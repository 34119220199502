import React, { FC } from 'react';
import {
    Button,
    createStyles,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    makeStyles,
    Paper,
    Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link as RouterLink } from 'react-router-dom';
import { ProblemContentCard } from '../Competitions/ProblemContentCard';
import { ProblemContentLoader } from '../Competitions/ProblemContentLoader';
import { CommentCount } from 'disqus-react';
import { CompetitionRoundViewData } from '../../datatypes/competition-round-view-data';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { ProblemList } from '../Competitions/ProblemList';
import { StatisticsViewer } from '../Competitions/StatisticsViewer';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AlarmIcon from '@material-ui/icons/Alarm';
import { format } from 'date-fns';

interface Props {
    competition_round: CompetitionRoundViewData;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#414557',
            boxShadow: '4px 4px 4px rgba(10, 10, 10, .3)',
            marginBottom: theme.spacing(2),
            borderRadius: 10,
        },
        tripleHeader: {
            display: 'flex',
            padding: theme.spacing(),
            paddingBottom: 0,
            marginBottom: theme.spacing(),

        }, headerMiddle: {
            flexGrow: 1,
            marginLeft: 20,
            marginTop: 'auto',
            marginBottom: 'auto',

        }, verticallyCentered: {
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        }, problemList: {
            // backgroundColor: '#FF7C7C',

            // borderRadius: 5,
            borderRight: '0px',
            [theme.breakpoints.up('md')]: {
                borderRight: '1px solid #555555',
                marginRight: 20,
            },
            // boxShadow: '0px 0px 1px #cccccc',
        }, centerBox: {
            backgroundColor: '#1D1E26',
            padding: 10,
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 20,
            borderRadius: 5,
            marginTop: theme.spacing(1),

        },
    }),
);

export const FeedItemJustFinished: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const round_url = `/ProgrammingCompetitions/${props.competition_round.organization.slug}/${props.competition_round.competition.slug}/${props.competition_round.year}/${props.competition_round.slug}`;


    const roundCaption = `${props.competition_round.organization.slug == "advent-of-code" ? "" :props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.organization.slug == 'leetcode' ? '#' : props.competition_round.year + ' - '}${props.competition_round.title}`;

    return <div className={classes.root}>

        <Grid container spacing={1}>
            <Grid item sm={12}  xl={2} md={2} xs={12} lg={12}   className={classes.verticallyCentered}>
                <CheckCircleOutlineIcon fontSize={'large'} style={{ marginRight: 10 }} />
                <Typography variant={'button'} className={classes.verticallyCentered}>Finished:</Typography>
            </Grid>
            <Grid item sm={12}  xl={9} md={9} xs={12} lg={12}  className={classes.verticallyCentered}>
                <Typography variant={'h6'}>
                    <Link component={RouterLink}
                          to={round_url}
                          color={'inherit'}>{roundCaption}</Link>

                </Typography>
            </Grid>

            {/*<Grid item sm={8} xl={3} md={3} xs={8} lg={8}  className={classes.verticallyCentered}>*/}

            {/*    <Typography>*/}
            {/*        {format(props.competition_round.end_time, "yyyy-MM-dd")}*/}
            {/*    </Typography>*/}

            {/*</Grid>*/}

            {/*<Grid item sm={4}  xl={1} md={1} xs={4} lg={4} className={classes.verticallyCentered}>*/}

            {/*    <Button*/}
            {/*        style={{marginLeft: 'auto'}}*/}
            {/*        variant='outlined'*/}
            {/*        size={'small'}*/}
            {/*        color='inherit'*/}
            {/*        href={props.competition_round.external_url}*/}
            {/*        target='_blank'*/}
            {/*        endIcon={<OpenInNewIcon />}*/}
            {/*    >*/}
            {/*        Open*/}
            {/*    </Button>*/}

            {/*</Grid>*/}

        </Grid>


        {/*<Divider />*/}

        <Grid container spacing={1} className={classes.centerBox}>
            <Grid item md={4} xs={12} className={classes.verticallyCentered}>
                <List component='nav' dense={true} className={classes.problemList}>

                    {props.competition_round.problem_list.map(p =>
                        <ListItem key={'jfli-' + p.id}>
                            <ListItem button dense={true} component={RouterLink}
                                      to={`${round_url}`}>
                                <ListItemText primary={p.title}
                                              secondary={p.test_sets.map(s =>
                                                  <StatisticsViewer key={s.id} s={s} />,
                                              )} />
                            </ListItem>
                        </ListItem>,
                    )}

                </List>
            </Grid>


            <Grid item md={8} xs={12} className={classes.verticallyCentered}>


                <ProblemContentLoader organization_slug={props.competition_round.organization.slug}
                                      competition_slug={props.competition_round.competition.slug}
                                      year={props.competition_round.year.toString()}
                                      round_slug={props.competition_round.slug}
                                      problem_slug={''}
                                      limit={2}
                                      programming_language_filter={[]}
                                      show_code_column={false}
                />

            </Grid>
        </Grid>
        {/*<Divider />*/}

        <div className={classes.tripleHeader}>

            <div style={{ paddingLeft: 5, paddingTop: 0, flexGrow: 1 }}>
                <Link component={RouterLink} to={round_url + '#roundcomments'} color={'inherit'}
                      style={{ marginTop: 0 }}>
                    <CommentCount
                        shortname='competitivecoders'
                        config={{
                            url: round_url + '#roundcomments',
                            identifier: `${props.competition_round.organization.slug}-${props.competition_round.competition.slug}-${props.competition_round.year}-${props.competition_round.slug}`,
                            title: `CompetitiveCoders.com - ${props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.year} - ${props.competition_round.title}`,
                        }}
                    >
                        Comments
                    </CommentCount>
                </Link>
            </div>
            <div style={{ paddingTop: 0 }}>

                <Link component={RouterLink} to={round_url} color={'inherit'}><Typography>Go to round
                    ---&gt;</Typography></Link>
            </div>

        </div>


    </div>;
};