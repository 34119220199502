import React, { FC } from 'react';
import { Container, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { CompetitionBreadcrumbsSkeleton } from './CompetitionBreadcrumbsSkeleton';
import { CompetitionHeaderSkeleton } from './CompetitionHeaderSkeleton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2

        }
    }),
);
export const CompetitionRoundViewSkeleton: FC = () => {

    const classes = useStyles();
    return <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
        <Container maxWidth={false}>
            <CompetitionBreadcrumbsSkeleton />

            <CompetitionHeaderSkeleton  />

        </Container>


    </Grid>;
};