

export async function updateYoutubeVideoRelevancy(video_id: string, is_relevant: boolean) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoRelevancy?video_id=${video_id}&is_relevant=${is_relevant}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        // body: JSON.stringify({video_id: video_id, is_relevant: is_relevant})
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function updateYoutubeVideoIgnored(video_id: string, is_ignored: boolean) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoIgnored?video_id=${video_id}&is_ignored=${is_ignored}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function updateContentCategory(video_id: string, category:number) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoCategory?video_id=${video_id}&category_value=${category}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function updateVideoLike(video_id: string, add_likes:number) {

    const response = await fetch(`https://localhost:7096/ContentItem/AddVideoLikes?video_id=${video_id}&add_likes=${add_likes}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function updateOrganization(video_id: string, organization_id:string) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoOrganization?video_id=${video_id}&organization_id=${organization_id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function getCompetitions(organization_id: string) {

    const response = await fetch(`https://localhost:7096/ContentItem/GetCompetitions?organization_id=${organization_id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    });

    const data = await response.json();
    return data;

    // const data = await response.json();
}

export async function getOrganizations() {

    const response = await fetch(`https://localhost:7096/ContentItem/GetOrganizations`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    });

    const data = await response.json();
    return data;

    // const data = await response.json();
}

export async function updateCompetition(video_id: string, competition_id:string) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoCompetition?video_id=${video_id}&competition_id=${competition_id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function updateRound(video_id: string, round_id:string) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoRound?video_id=${video_id}&round_id=${round_id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}
export async function setProblem(video_id: string, problem_id:string) {

    const response = await fetch(`https://localhost:7096/ContentItem/SetVideoProblem?video_id=${video_id}&problem_id=${problem_id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}
export async function removeProblem(video_id: string, problem_id:string) {

    const response = await fetch(`https://localhost:7096/ContentItem/RemoveVideoProblem?video_id=${video_id}&problem_id=${problem_id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function setProgrammingLanguage(video_id: string, programming_language:string) {
    const qstring = new URLSearchParams({
        video_id: video_id,
        programming_language: programming_language
    });
    const response = await fetch(`https://localhost:7096/ContentItem/SetProgrammingLanguage?${qstring}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });

    return response.status == 200;

    // const data = await response.json();
}

export async function getRounds(competition_id: string, year: number) {

    const response = await fetch(`https://localhost:7096/ContentItem/GetRounds?competition_id=${competition_id}&year=${year}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    });

    const data = await response.json();
    return data;

    // const data = await response.json();
}

export async function getProblems(round_id: string, video_id:string ) {

    const response = await fetch(`https://localhost:7096/ContentItem/GetProblems?round_id=${round_id}&video_id=${video_id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    });

    const data = await response.json();
    return data;

    // const data = await response.json();
}

export async function getClassification(video_id: string) {

    const response = await fetch(`https://localhost:7096/ContentItem/GetClassification?video_id=${video_id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },

    });

    const data = await response.json() as VideoClassification;
    return data;

    // const data = await response.json();
}


interface VideoClassification{
    organization_id: string,
    competition_id: string,
    year: string,
    round_id: string,
    problem_ids: [string],
    content_category: number,
    ignored: boolean | null,
    relevant: boolean | null,
    programming_language: string | null
}