import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Container, createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core';
import { CommentCount, DiscussionEmbed } from 'disqus-react';
import { CompetitionRoundViewData } from '../../datatypes/competition-round-view-data';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import { CompetitionHeader } from './CompetitionHeader';
import { CompetitionWelcomeMedia } from './CompetitionWelcomeMedia';
import { ProblemList } from './ProblemList';
import { ProblemContentContainer } from './ProblemContentContainer';
import { ProblemContentLoader } from './ProblemContentLoader';
import Typography from '@material-ui/core/Typography';
import ChatIcon from '@material-ui/icons/Chat';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FilterListIcon from '@material-ui/icons/FilterList';
import { ProgrammingLanguageFilterBox } from './ProgrammingLanguageFilterBox';
import { AdventOfCodePrivateLeaderboardWidget } from '../../widgets/AdventOfCodePrivateLeaderboard';

interface Props {
    competition_round: CompetitionRoundViewData;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header_image: {
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: 5,
            boxShadow: '2px 2px 5px #1c1c1c',
            border: '1px solid #444',
        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2,

        },
        rightcolumn: {
            // marginTop: theme.spacing(2),
            padding: 5,
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
        },
    }),
);


const CompetitionRoundView: FC<Props> = (props) => {
    const classes = useStyles();

    const [shown_languages, setShownLanguages] = useState<string[]>([]);

    const competition_round_link = {
        title: props.competition_round.title,
        slug: props.competition_round.slug,
        start_time: props.competition_round.start_time,
        end_time: props.competition_round.end_time,
        external_url: props.competition_round.external_url,
        internal_url: 'NA',
        id: 'N/A',
    };


    function selectLanguageFilter(language: string) {

        const newArr = shown_languages.filter(l => l != language);

        if (shown_languages.length == newArr.length) {
            newArr.push(language);
        }

        setShownLanguages(newArr);
    }


    let header_image = props.competition_round.competition.slug == 'hacker-cup' ? '/logos/fbhc.jpg' :
        props.competition_round.competition.slug == 'kick-start' ? '/logos/gks.JPG' :
            props.competition_round.competition.slug == 'code-jam' ? '/logos/gcj.jpg' :
                props.competition_round.organization.slug == 'leetcode' ? '/logos/leetcode.jpg' :
                    null;

    if ( props.competition_round.competition.slug == 'advent-of-code' && props.competition_round.year == 2022 ){
        header_image = '/aoc/' + props.competition_round.slug + '.jpg';
    }

    return (
        <>
            <Helmet>
                <title>{`${props.competition_round.organization.title}${props.competition_round.organization.slug == 'advent-of-code' ? '' : ' '+props.competition_round.competition.title}${props.competition_round.organization.slug=='leetcode' ? '' : ' '+props.competition_round.year} ${props.competition_round.title} solutions`}</title>
                <meta
                    name="description"
                    content={`See solutions and walkthroughs of ${props.competition_round.organization.title}${props.competition_round.slug == 'advent-of-code' ? '' : ' '+props.competition_round.competition.title}${props.competition_round.organization.slug=='leetcode' ? '' : ' '+props.competition_round.year} ${props.competition_round.title} on CompetitiveCoders.com!`}
                />
            </Helmet>

            <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
                <Container maxWidth={false} component={'main'}>
                    <ContestBreadcrumbs competition={props.competition_round.competition}
                                        competition_round={competition_round_link}
                                        organization={props.competition_round.organization}
                                        year={props.competition_round.year}
                                        problem={null} />


                    <CompetitionHeader competition={props.competition_round.competition}
                                       organization={props.competition_round.organization}
                                       competition_round={competition_round_link} year={props.competition_round.year} />
                    <br />
                    {
                        props.competition_round.introduction_video_id ?
                            <CompetitionWelcomeMedia
                                youtube_video_id={props.competition_round.introduction_video_id} /> :
                            <div></div>
                    }

                    {
                        header_image != null && props.competition_round.introduction_video_id == null ?
                            <div style={{textAlign: 'center'}}>
                                <img src={header_image} alt={props.competition_round.competition.title} className={classes.header_image} />
                            </div>
                            // <CardMedia
                            //     // className={classes.media}
                            //     image={header_image}
                            //     title={`${props.competition_round.organization.slug} ${props.competition_round.competition.title}` }
                            // />
                            :

                            <div></div>
                    }

                    <div style={{ float: 'right', padding: 10 }}>
                        <AnchorLink href={`#roundcomments`} style={{ textDecoration: 'none', color: '#FDCA86' }}>


                            <CommentCount
                                shortname='competitivecoders'
                                config={{
                                    url: `https://www.competitivecoders.com/ProgrammingCompetitions/${props.competition_round.organization.slug}/${props.competition_round.competition.slug}/${props.competition_round.year}/${props.competition_round.slug}`,
                                    identifier: `${props.competition_round.organization.slug}-${props.competition_round.competition.slug}-${props.competition_round.year}-${props.competition_round.slug}`,
                                    title: `CompetitiveCoders.com - ${props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.year} - ${props.competition_round.title}`,
                                }}
                            >
                                Comments
                            </CommentCount>
                            &nbsp;
                            <ChatIcon />
                        </AnchorLink>
                    </div>

                    <br />
                    <br />


                </Container>

                {props.competition_round.organization.slug == 'consid' ?
                    null
                    :
                    <ProblemList key={'r5'} problems={props.competition_round.problem_list} />
                }


                <Divider variant={'middle'} />

                <div style={{ marginTop: 40, paddingLeft: 22 }}>
                    <div className={classes.sectionMobile}>
                        <ProgrammingLanguageFilterBox callbackFn={selectLanguageFilter} selected_languages={shown_languages} sticky={false} show_title={true}/>
                    </div>
                    <Typography variant={'h5'}>.solution_videos({shown_languages.length > 0 ? <span
                        style={{ color: '#FB9583' }}><FilterListIcon />{shown_languages.join(',')}</span> : null})</Typography>
                </div>

                <div style={{ padding: 20, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>
                    <ProblemContentLoader organization_slug={props.competition_round.organization.slug}
                                          competition_slug={props.competition_round.competition.slug}
                                          year={props.competition_round.year.toString()}
                                          round_slug={props.competition_round.slug}
                                          problem_slug={''}
                                          limit={null}
                                          programming_language_filter={shown_languages}
                    />
                </div>

                <Divider variant={'middle'} />

                {props.competition_round.organization.slug != 'advent-of-code' &&
                props.competition_round.organization.slug != 'consid'
                    ?
                    <div style={{ marginTop: 40, paddingLeft: 22 }}>
                        <Typography variant={'h5'}>Problems</Typography>
                    </div> : null}


                {props.competition_round.problem_list.map(p =>
                    props.competition_round.organization.slug == 'advent-of-code' ||
                    props.competition_round.organization.slug == 'consid'
                        ?
                        <></> :
                        <><ProblemContentContainer problem={p} key={'pc' + p.slug}
                                                   organization_slug={props.competition_round.organization.slug}
                                                   competition_slug={props.competition_round.competition.slug}
                                                   year={props.competition_round.year}
                                                   round_slug={props.competition_round.slug}
                                                   show_as_part_of_problem_list={true}
                                                   programming_language_filter={shown_languages}

                        /><Divider variant={'middle'} style={{ marginTop: 20, marginBottom: 20 }} /></>,
                )}


                {/*<div style={{ marginTop: 40, paddingLeft: 22 }}>*/}
                {/*    /!*<Typography variant={'h5'}>Problems</Typography>*!/*/}
                {/*</div>*/}


                <Container id={'roundcomments'} style={{ marginTop: 20 }}>


                    <DiscussionEmbed
                        shortname='competitivecoders'
                        config={{
                            url: `https://www.competitivecoders.com/ProgrammingCompetitions/${props.competition_round.organization.slug}/${props.competition_round.competition.slug}/${props.competition_round.year}/${props.competition_round.slug}`,
                            identifier: `${props.competition_round.organization.slug}-${props.competition_round.competition.slug}-${props.competition_round.year}-${props.competition_round.slug}`,
                            title: `CompetitiveCoders.com - ${props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.year} - ${props.competition_round.title}`,
                            language: 'en_US',
                        }}
                    />
                </Container>

            </Grid>
            <Grid item sm={12} xl={2} lg={3} className={classes.rightcolumn}>
                <div className={classes.sectionDesktop}>
                    {props.competition_round.organization.slug=='advent-of-code'
                        ? <AdventOfCodePrivateLeaderboardWidget limit={3}/>
                        :null
                    }
                    <ProgrammingLanguageFilterBox callbackFn={selectLanguageFilter} selected_languages={shown_languages} sticky={true}  show_title={true}/>


                </div>
            </Grid>

        </>
    );
};

export default CompetitionRoundView;

