import React, { FC } from 'react';
import { Container, createStyles, Divider, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import { CompetitionBreadcrumbsSkeleton } from './components/Competitions/CompetitionBreadcrumbsSkeleton';
import { CompetitionHeaderSkeleton } from './components/Competitions/CompetitionHeaderSkeleton';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { YoutubeAttachmentPanel } from './components/Feed/FeedItems/YoutubeAttachmentPanel';
import { FeedItemNextCompetitionLoader } from './components/Feed/FeedItemNextCompetitionLoader';
import { DiscordWidget } from './widgets/DiscordWidget';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 0,
        },
        blockquote: {
            color: '#FB9583',
            fontFamily: 'Tahoma, sans-serif',
            fontSize: 40,
            width: '80%',
            margin: '0 auto',
            '& h1': {
                fontSize: '4rem',
            }, '& cite': {
                fontSize: '1.5rem',
            },
        },
        blockquote_p: {
            fontStyle: 'italic',
            marginBottom: 0,
            '&:before': {
                content: '“',
                fontFamily: 'Georgia',
                fontSize: '8rem',
                margin: '-2rem 0 0 -4rem',
                position: 'absolute',
                opacity: 0.5,
            },
            '&:after': {
                content: '”',
                margin: '-1rem -4rem 0 0',
                fontFamily: 'Georgia',
                fontSize: '8rem',
                position: 'absolute',
                opacity: 0.5,
            },
        },


    }),
);

export const About: FC = () => {
    const classes = useStyles();

    return <Container className={classes.root} component={'main'}>
        <Helmet>
            <title>About CompetitiveCoders.com - Rediscover the joy of coding!</title>
            <meta
                name="description"
                content="CompetitiveCoders.com is a community of competitive coders and their solutions to competitions like Google Code jam, Meta Hackercup and Advent of Code"
            />

        </Helmet>

        <Grid container spacing={4}>
            <Grid item xs={12}>

                <blockquote className={classes.blockquote}>
                    <p className={classes.blockquote_p}><div style={{textAlign: 'center'}}>Rediscover <strong>the joy of programming!</strong></div></p>
                </blockquote>
                <br />
                <br/>
                <br/>
                <br/>
                <Typography variant={'h2'}>Competitive coding?</Typography>
                <br />

                <Typography>
                    Competitive coding is a sport where you solve programming challenges on time while competing with
                    others. It is what you do to show off to your colleagues, hurt your brain and feel completely
                    demoralized ;) <br />(but it is fun and highly addictive!)
                </Typography>

                <br />
                <Typography>
                    There are many different competitions and organizers so we help you with where to start and how to
                    get going! In short, we focus on the bigger ones and particularly the fun ones!
                </Typography>
                <br />
                <br />
                <Typography variant={'h2'}>Where do I begin?</Typography>
                <Typography>
                    Just pick one in the <Link component={RouterLink} to={`/calendar/${new Date().getFullYear()}`}>calendar</Link> and give it a
                    shot! Or why not just hop on the next one:
                </Typography>
                <br />
                <FeedItemNextCompetitionLoader />
                <br />
                <Typography>
                    The competitions we chose to cover (currently) are the &quot;big ones&quot; Google Kick Start,
                    Google Code Jam and Meta (Facebook) Hacker Cup - but to make sure there is something at least every
                    week, we added the small ones from Leetcode as well. More are to come, but we aim to filter hard to
                    not overwhelm.
                </Typography>

                <br />
                <br />
                <Typography variant={'h2'}>Learn from the experts</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <YoutubeAttachmentPanel
                    attachment={{ video_id: 'ueNT-w7Oluw', type: 'youtube', views: 0, likes: 0, comments: 0 }}
                     />
            </Grid>
            <Grid item xs={12} md={6}>
                <YoutubeAttachmentPanel
                    attachment={{ video_id: 'xAeiXy8-9Y8', type: 'youtube', views: 0, likes: 0, comments: 0 }}
                     />
            </Grid>
            <Grid item xs={12} md={6}>
                <YoutubeAttachmentPanel
                    attachment={{ video_id: 'bVKHRtafgPc', type: 'youtube', views: 0, likes: 0, comments: 0 }}
                     />
            </Grid>
            <Grid item xs={12} md={6}>
                <YoutubeAttachmentPanel
                    attachment={{ video_id: 'bSdp2WeyuJY', type: 'youtube', views: 0, likes: 0, comments: 0 }}
                     />
            </Grid>
            <Grid item xs={12}>
                <br />
                <br />
                <Divider />
                <br />
                <br />

                <Typography variant={'h4'}>CompetitiveCoders.com?</Typography>
                <br />
                <Typography>This is the site for you to find competitions to join, show off and discuss how
                    it went and maybe foremost see how others solved the problems. We use machine learning (much wow!)
                    to collect related content and present it with the competition rounds and problems for all to find.
                    Along with everything we have discussion threads for you to vent your frustration or show of your
                    brilliant solutions in. </Typography>
                <br />
                <Typography variant={'h2'}>What now?</Typography>
                <br />
                <Typography>
                    Find a competition to join! Go to the <Link component={RouterLink} to={`/calendar/${new Date().getFullYear()}`}>calendar</Link>.
                    Or why not botanize through some content and join your peers in our Discord server <Link
                    component={RouterLink} to={'/'}>on the main page</Link>!
                </Typography>
            </Grid>
        </Grid>


        <Divider variant={'middle'} style={{ marginTop: 40, marginBottom: 40 }} />

        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                {/*<DiscordWidget serverId='998511827287810121' />*/}
            </Grid>
            <Grid item xs={12} md={12}>

            </Grid>
        </Grid>



    </Container>;

};