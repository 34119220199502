import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import {
    Box,
    CardMedia,
    Container,
    createStyles,
    Divider,
    Grid,
    Link,
    makeStyles,
    Paper,
    Theme,
} from '@material-ui/core';
import { CommentCount, DiscussionEmbed, } from 'disqus-react';
import { CompetitionRoundProblemLink, CompetitionRoundViewData } from '../../datatypes/competition-round-view-data';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import { CompetitionHeader } from './CompetitionHeader';
import { CompetitionWelcomeMedia } from './CompetitionWelcomeMedia';
import { ProblemList } from './ProblemList';
import { ProblemContentContainer } from './ProblemContentContainer';
import { DisplayFilterUI } from './DisplayFilterUI';
import { ProblemContentLoader } from './ProblemContentLoader';
import { YoutubeAttachmentPanel } from '../Feed/FeedItems/YoutubeAttachmentPanel';
import Typography from '@material-ui/core/Typography';

interface Props {
    competition_round: CompetitionRoundViewData;
    problem: CompetitionRoundProblemLink
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header_image: {
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: 5,
            boxShadow: '2px 2px 5px #1c1c1c',
            border: '1px solid #444',
        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2

        }
    }),
);


const CompetitionProblemView: FC<Props> = (props) => {
    const classes = useStyles();


    const competition_round_link = {
        title: props.competition_round.title,
        slug: props.competition_round.slug,
        start_time: props.competition_round.start_time,
        end_time: props.competition_round.end_time,
        external_url: props.competition_round.external_url,
        internal_url: 'N/A',
        id: 'N/A'

    };


    const header_image = props.competition_round.competition.slug == 'hacker-cup' ? '/logos/fbhc.jpg' :
        props.competition_round.competition.slug == 'kick-start' ? '/logos/gks.JPG' :
            props.competition_round.competition.slug == 'code-jam' ? '/logos/gcj.jpg' :
                props.competition_round.organization.slug == 'leetcode' ? '/logos/leetcode.jpg' :
                    null;

    return (
        <>
            <Helmet>
                <title>{`Solutions to '${props.problem.title}' (${props.competition_round.organization.title}${props.competition_round.organization.slug == 'advent-of-code' ? '' : ' '+props.competition_round.competition.title}${props.competition_round.organization.slug=='leetcode' ? '' : ' '+props.competition_round.year} ${props.competition_round.title})`}</title>
                <meta
                    name="description"
                    content={`See how others did on the ${props.problem.title} problem in ${props.competition_round.organization.title}${props.competition_round.slug == 'advent-of-code' ? '' : ' '+props.competition_round.competition.title}${props.competition_round.organization.slug=='leetcode' ? '' : ' '+props.competition_round.year}${props.competition_round.title} on CompetitiveCoders.com!`}
                />
            </Helmet>
            <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} component={'main'}>
                <Container maxWidth={false}>
                    <ContestBreadcrumbs competition={props.competition_round.competition}
                                        competition_round={competition_round_link}
                                        organization={props.competition_round.organization}
                                        year={props.competition_round.year}
                                        problem={props.problem}/>


                    {/*<CompetitionHeader competition={props.competition_round.competition}*/}
                    {/*                   organization={props.competition_round.organization}*/}
                    {/*                   competition_round={competition_round_link} year={props.competition_round.year} />*/}

                </Container>


                <ProblemContentContainer problem={props.problem} key={'pc' + props.problem.id}
                                         organization_slug={props.competition_round.organization.slug}
                                         competition_slug={props.competition_round.competition.slug}
                                         year={props.competition_round.year}
                                         round_slug={props.competition_round.slug}
                                         show_as_part_of_problem_list={false}
                                         programming_language_filter={[]}
                />

                {/*<Divider variant={'middle'} style={{ marginTop: 20 }} />*/}

                <div style={{ marginTop: 40, paddingLeft: 22 }}>
                </div>


                <Container id={'comments'}>
                    <DiscussionEmbed
                        shortname='competitivecoders'
                        config={{
                            url: `https://www.competitivecoders.com/ProgrammingCompetitions/${props.competition_round.organization.slug}/${props.competition_round.competition.slug}/${props.competition_round.year}/${props.competition_round.slug}/${props.problem.slug}`,
                            identifier: `${props.competition_round.organization.slug}-${props.competition_round.competition.slug}-${props.competition_round.year}-${props.competition_round.slug}-${props.problem.slug}`,
                            title: `CompetitiveCoders.com - ${props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.year} - ${props.competition_round.title} - ${props.problem.title}`,
                            language: 'en_US',
                        }}
                    />
                </Container>

            </Grid>


        </>
    );
};

export default CompetitionProblemView;

