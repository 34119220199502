import React, { FC } from 'react';
import {
    Checkbox,
    Container,
    createStyles, Divider,
    Grid,
    LinearProgress, Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { School } from '@material-ui/icons';
import { LearningChapterView } from './LearningChapterView';
import { AdventOfCodePrivateLeaderboardWidget } from './widgets/AdventOfCodePrivateLeaderboard';
import { DiscordWidget } from './widgets/DiscordWidget';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        listRoot: {
            width: '100%',
            maxWidth: 360,
        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2,

        },
        verticallyCentered: {
            // display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        },
        horizontallyCentered: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        containerPadding: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        outerContainer: {
            backgroundColor: '#424242',
            borderRadius: 5,
            marginBottom: theme.spacing(6),
            boxShadow: '2px 2px 5px #1c1c1c',
        },
        videoContainer: {
            display: 'flex',
            overflowX: 'scroll',
            paddingBottom: 10,
            // '&::-webkit-scrollbar': {
            //     width: '0.3em',
            //     // height: '0.2em',
            // },
            // '&::-webkit-scrollbar-track': {
            //     // backgroundColor: '#101010',
            //     // borderRadius: 20,
            //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            // },
            // '&::-webkit-scrollbar-thumb': {
            //     backgroundColor: '#d6dee1',
            //     borderRadius: 20,
            //     border: '6px solid transparent',
            //     backgroundClip: 'content-box',
            // }
            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: '#2b2b2b',
                borderRadius: 8,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: '#6b6b6b',
                // minHeight: 10,
                width: '0.2em',
                border: '3px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: '#2b2b2b',
            },

        },
        checkBox: {
            // color: 'pink',
            '&:hover': {
                backgroundColor: '#505050',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },
        },
        progressColorPrimary: {
            backgroundColor: 'darkgray',
        },
        progressBarColorPrimary: {
            backgroundColor: 'pink',
        },


    }),
);


export const Resources: FC = () => {

    const classes = useStyles();

    return <>
        <Helmet>
            <title>{`Resources for learning competitive programming`}</title>
            <meta
                name='description'
                content='A curated collection of resources for learning and improving in competitive programming competitions. Contains information for learning data structures and algorithms usable for coding contests.'
            />
        </Helmet>
        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} style={{ padding: 40 }}>
            <div style={{ display: 'flex' }}>
                <div style={{ paddingTop: 10, marginRight: 10 }}>
                    <School fontSize={'large'} />
                </div>
                <div>
                    <Typography variant='h4' component='h1'>
                        Competitive programming Learning Center
                    </Typography>
                    <Typography variant='h6' component='h2'>
                        A curated study list for improving in coding contests
                    </Typography>
                </div>
            </div>
            <br />
            <Divider variant={'middle'} />
            <br />
            <br />
            <Typography style={{ padding: 20 }} variant={'body1'}>To improve your results in competitive programming
                competitions and coding
                contests, you need to study the specifics of your programming language of choice, computer science
                concepts and algorithms and data structures in particular. Here we try to collect the most useful
                resources for you to improve.</Typography>

            <br />
            <br />
            <Divider variant={'middle'} />
            <br />
            <br />
            <Typography variant={'h4'} component={'h3'}>
                Competitive programming
            </Typography>
            <ul>
                <li>
                    <Link target={'_blank'} href={'https://cp-algorithms.com/'}>https://cp-algorithms.com/</Link><br />
                    Descriptions of many algorithms and data structures especially popular in field of competitive
                    programming
                </li>
                <li>
                    <Link target={'_blank'}
                          href={'https://github.com/vicky002/AlgoWiki/tree/gh-pages/Competitive-Programming'}>Algowiki -
                        Competitive programming</Link><br />
                    A curated list of awesome Competitive Programming, Algorithm and Data Structure resources.
                </li>
                <li>
                    <Link target={'_blank'} href={'https://codeforces.com/blog/entry/23054'}>Codeforces blog - An
                        awesome list for competitive programming!</Link><br />
                    The incredibly popular blog post on the popular competitive programming site Codeforces that lists
                    selected useful links for learning and improving in competitive coding!
                </li>
            </ul>

            <br />
            <br />
            <Typography variant={'h4'} component={'h3'}>
                Algorithms and data structures
            </Typography>
            <ul>
                <li>
                    <Link target={'_blank'} href={'https://en.wikibooks.org/wiki/Algorithms'}>WikiBooks -
                        Algorithms</Link><br />
                    This book aims to be an accessible introduction to the design and analysis of efficient algorithms.
                    Throughout the book we will introduce only the most basic techniques and describe the rigorous
                    mathematical methods needed to analyze them. The goal of the book is to show you how you can
                    methodically apply different techniques to your own algorithms to make them more efficient. While
                    this book mostly highlights general techniques, some well-known algorithms are also looked at in
                    depth. This book is written so it can be read from &quot;cover to cover&quot; in the length of a
                    semester.
                </li>
                <li>
                    <Link target={'_blank'} href={'https://en.wikibooks.org/wiki/Data_Structures'}>Algowiki - Data
                        structures</Link><br />
                    This book is about the creation and analysis of efficient data structures. This book is about the
                    creation and analysis of efficient data structures.
                </li>
                <li>
                    <Link target={'_blank'}
                          href={'https://ocw.mit.edu/courses/6-006-introduction-to-algorithms-fall-2011/'}>MIT -
                        Introduction to algorithms</Link><br />
                    The MIT Open courseware lectures on Algorithms. This is not for when you are starting out in
                    competitive programming, but rather to dive deeper when the standard libraries doesn&apos;t take you
                    further.
                </li>

            </ul>
            <br />
            <br />
            <Typography variant={'h4'} component={'h3'}>
                Language specifics
            </Typography>
            <Typography variant={'body2'}>The best programming language for competitive programming when you are
                starting out is the programming language you already know and are comfortable with. Whatever language
                you choose is not what will hold you back in the beginning; specific language performance and such is a
                luxury problem you have once you&apos;re already good at programming competitions (Then you should
                probably choose c++ ;) ) </Typography>
            <ul>
                <li>
                    <Link target={'_blank'} href={'https://www.geeksforgeeks.org/python-tricks-competitive-coding/'}>Python
                        tricks for competitive programming</Link>
                </li>
                <li>
                    <Link target={'_blank'}
                          href={'https://www.geeksforgeeks.org/java-tricks-competitive-programming-java-8/'}>Java Tricks
                        for Competitive Coding</Link>
                </li>

                <li>
                    <Link target={'_blank'}
                          href={'https://hackernoon.com/javascript-for-algorithms-competitive-programming-45cf723cd16f'}>JavaScript
                        for Algorithmic Competitive Programming</Link>
                </li>
                <li>
                    <Link target={'_blank'}
                          href={'https://www.geeksforgeeks.org/c-tricks-competitive-programming-c-11/'}>C++ tricks for
                        competitive programming</Link>
                </li>
                <li>
                    <Link target={'_blank'}
                          href={'https://github.com/kenkoooo/competitive-programming-rs'}>Competitive Programming
                        Snippets in Rust</Link>
                </li>

            </ul>
            <br />
            <br />
            <Typography variant={'h4'} component={'h3'}>
                Books
            </Typography>
            <ul>
                <li>
                    <Link target={'_blank'} href={'https://cses.fi/book/book.pdf'}>Competitive Programmer’s Handbook
                        (PDF)</Link><br />
                    The purpose of this book is to give you a thorough introduction to competitive
                    programming. It is assumed that you already know the basics of programming,
                    but no previous background in competitive programming is needed
                </li>

            </ul>


        </Grid>


        {/*Right hand side*/}
        <Grid item sm={12} xl={2} lg={3}>
            <aside style={{ width: '100%' }}>


                {/*<TwitterWidget />*/}
                <DiscordWidget serverId='998511827287810121' />
                <br />

                {/*<div className={classes.sectionDesktop} style={{ width: '100%', marginLeft: 20 }}>*/}

                {/*   */}

                {/*</div>*/}


            </aside>
        </Grid>
    </>;
};