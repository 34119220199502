import React  from "react";
import { createStyles, Link, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import { AppBar } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { ReactElement } from "react";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      top: 'auto',
      bottom: 0,
      display: 'flex',
      minheight: '100vh',
      flexdirection: 'column',
      backgroundColor: theme.palette.background.default,
        marginTop: 50
    },
    grow: {
      flexGrow: 1,
    },
  }),
);



export default function Footer(): ReactElement {

    const classes = useStyles();

    return (
        <AppBar component={'footer'} position="static"  className={classes.footer}>
          <Container maxWidth="md">
            {/*<Toolbar>*/}
              <Typography variant="overline">
                © 2023 CompetitiveCoders.com
              </Typography>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Typography variant="overline">
                    <Link href={'https://www.competitivecoders.com/privacypolicy.html'} target={'_blank'} color={'inherit'} >Privacy policy</Link>
                </Typography>
                &nbsp;-&nbsp;
                <Typography variant="overline">
                    <Link href={'https://www.competitivecoders.com/cookiepolicy.html'} target={'_blank'} color={'inherit'} >Cookie policy</Link>
                </Typography>
                &nbsp;-&nbsp;
                <Typography variant="overline">
                    <Link href={'https://www.competitivecoders.com/terms.html'} target={'_blank'} color={'inherit'} >Terms of use</Link>
                </Typography>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Typography variant="overline">
                Colorscheme from <Link href={'https://ui.draculatheme.com/'} target={'_blank'} style={{color: '#FB7FBD'}}>DraculaUI</Link>
              </Typography>



            {/*</Toolbar>*/}
          </Container>
        </AppBar>
    )
}