import * as React from 'react';
import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { LinkAttachment } from './feed-interfaces';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        image: {
            flexGrow: 1,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
            height: '100%',
        },
        centerAlign: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#1D1E26'
}
    }));


export const LinkAttachmentPanel: React.FC<{ attachment: LinkAttachment, height?: number }> = ({
                                                                                                   attachment,
                                                                                                   height = 350,
                                                                                               }) => {
    const classes = useStyles();

    return (
        <Link
            color={'inherit'}
            href={attachment.external_url} target={'_blank'}>
        <div style={{ height: height }} className={classes.centerAlign}>

                {attachment.image_url !== '' ?
                    <img src={attachment.image_url}  style={{ objectFit: 'contain', height: height }} />
                    :
                    <DescriptionIcon fontSize={'large'} />
                }

        </div>
        </Link>);
};