import React, { FC } from 'react';
import { CompetitionLinksWidgetList } from './CompetitionLinksWidgetList';
import { useQuery } from 'react-query';
import { fetchContestRounds, fetchExternalContent } from '../data/repository';
import { CompetitionLinksWidgetListSkeleton } from './CompetitionLinksWidgetListSkeleton';
import { Soft404Widget } from '../components/Competitions/Soft404Widget';

interface Props {
    elements: number,
    source: string
}

export const CompetitionLinksWidgetListLoader: FC<Props> = (props) => {

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    // return <ProblemContentSkeleton />;

    const { isLoading, error, status, data } =
        useQuery(['contests', {
                source: props.source,
                elements: props.elements,
            }], () => fetchContestRounds(props.source, props.elements)
            , { staleTime: 600000 });

    if (isLoading) return (
        <>
            <CompetitionLinksWidgetListSkeleton />
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <CompetitionLinksWidgetList competitionRounds={data} source={props.source} /> :
        <div>&quot unknown &quot data received :(</div>;


};