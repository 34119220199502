import React from 'react';
import { Chip, createStyles, Divider, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { ContentItem, FeedItemAttachmentSwitch } from '../../datatypes/feed-interfaces';
import { formatISO9075 } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommentIcon from '@material-ui/icons/Comment';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { AdminMenu } from './AdminMenu';
import { LikeMenu } from './LikeMenu';
import { ContentTypeIcon } from './ContentTypeIcon';

interface FeedItemPanelProps {
    feedItem: ContentItem;
    // logoNode: React.ReactNode,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(8),
        },
        header: {
            padding: theme.spacing(3),
        },
        preWrapper: {
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
        },
        verticallyCentered: {
            // display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        },
        horizontallyCentered: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        containerPadding: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
            paddingBottom: 20,
        },
        outerContainer: {
            backgroundColor: '#383A58',
            borderRadius: 5,
            marginBottom: theme.spacing(6),
            boxShadow: '2px 2px 5px #1c1c1c',
        },
        breakWord: {
            wordBreak: 'break-word',
        },
        roundLink: {
            '&:hover': {
                // backgroundColor: '#505050',
                transform: 'scale(1.05)',
                cursor: 'pointer',

            },
        },
        adminButtonContainer: {
            float: 'right',
        },
        chipstat: {
            backgroundColor: '#15171B',
        },
    }),
);

export const FeedItemPanel: React.FC<FeedItemPanelProps> = ({ feedItem }) => {
    // export const PostBase: React.FC<Props> = ({feedItem, logoNode, contentData}) => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} className={classes.outerContainer} key={feedItem.id}>

                <Grid container spacing={2} className={classes.containerPadding}>

                    <Grid item xs={2} sm={1} lg={1} className={classes.verticallyCentered}>
                        <div className={classes.horizontallyCentered}>
                            <ContentTypeIcon content_category={feedItem.content_category} />
                        </div>

                    </Grid>
                    <Grid item xs={10} sm={11} lg={11} className={classes.verticallyCentered}>
                        <Typography variant={'h6'} className={classes.breakWord}>

                            {
                                feedItem.attachment.type == 'youtube' ?
                                    <div className={classes.adminButtonContainer}>
                                        <AdminMenu title={feedItem.title} video_id={feedItem.attachment.video_id} />
                                    </div>
                                    :
                                    <></>
                            }

                            <div style={{ marginBottom: 0 }}>{feedItem.title}</div>
                            <div style={{ marginTop: -10 }}>
                                <Typography variant={'overline'}>
                                    <Link href={feedItem.author.external_url} target='_blank'>
                                        {feedItem.author.name}
                                    </Link>
                                    - <span>{formatISO9075(feedItem.timestamp)}</span>
                                </Typography>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>

                <Divider />

                <Grid container spacing={3} className={classes.containerPadding}>
                    <Grid item xs={12} sm={6} className={classes.verticallyCentered}>
                        <FeedItemAttachmentSwitch attachment={feedItem.attachment} />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.verticallyCentered}>
                        <pre className={classes.preWrapper}>
                            <Typography>{`${feedItem.description.substr(0, 250)}...`}</Typography>
                        </pre>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={3} className={classes.containerPadding}>
                    <Grid item xs={12} className={classes.verticallyCentered}>


                        {feedItem.attachment.type == 'youtube' ?
                            <div>
                                <Chip
                                    className={classes.chipstat}
                                    icon={<VisibilityIcon />}
                                    label={feedItem.attachment.views ? feedItem.attachment.views : 0}
                                    disabled={true}
                                />
                                &nbsp;
                                <Chip
                                    className={classes.chipstat}
                                    icon={<ThumbUpAltIcon />}
                                    label={feedItem.attachment.likes ? feedItem.attachment.likes : 0}
                                    disabled={true}
                                />
                                &nbsp;
                                <Chip
                                    className={classes.chipstat}
                                    icon={<CommentIcon color={'action'} />}
                                    label={feedItem.attachment.comments ? feedItem.attachment.comments : 0}
                                    disabled={true}
                                />
                                &nbsp;
                                {feedItem.round ?
                                    <Link component={RouterLink} to={feedItem.round.internal_url} color={'secondary'}
                                          underline={'none'}>
                                        <Chip
                                            icon={<EmojiEventsIcon color={'secondary'} />}
                                            label={feedItem.round.title}
                                            color={'secondary'}
                                            className={classes.roundLink}
                                        /> </Link> : <></>
                                }

                                <LikeMenu video_id={feedItem.attachment.video_id} />

                            </div>
                            : <div>&nbsp;</div>
                        }
                    </Grid>
                </Grid>

            </Grid>


        </>
    );
};
