import React, { FC } from 'react';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import {
    CompetitionLink,
    CompetitionOrganizationLink,
    CompetitionRoundLink,
} from '../../datatypes/competition-round-view-data';
import { Helmet } from 'react-helmet';
import { createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import { CompetitionHeader } from './CompetitionHeader';
import { CompetitionYearViewSkeleton } from './CompetitionYearViewSkeleton';
import { ProblemContentContainer } from './ProblemContentContainer';
import { ProblemContentLoader } from './ProblemContentLoader';
import { CompetitionWelcomeMedia } from './CompetitionWelcomeMedia';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import UpdateIcon from '@material-ui/icons/Update';

export interface CompetitionYearViewData {
    slug: string,
    competition: CompetitionLink
    organization: CompetitionOrganizationLink,
    year: number,
    rounds: CompetitionRoundLink[],

}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2

        },
        grayed: {
            color: 'lightgray',
            fontStyle: 'italic',
        },
        normal: {

        }
    }),
);
interface Props {
    competition_data: CompetitionYearViewData;
}


export const CompetitionYearView: FC<Props> = (props: Props) => {

    const classes = useStyles();

    const baseUrl = `/ProgrammingCompetitions/${props.competition_data.organization.slug}/${props.competition_data.competition.slug}/${props.competition_data.year}/`;

    props.competition_data.rounds.sort((a, b) => a.start_time < b.start_time ? 1 : -1);

    return <>

            <Helmet>
                <title>{`Solutions to ${props.competition_data.organization.title}${props.competition_data.competition.slug == 'advent-of-code' ? '' : ' '+props.competition_data.competition.title} ${props.competition_data.year}`}</title>
                <meta
                    name="description"
                    content={`See other's solutions and walkthroughs of ${props.competition_data.organization.title}${props.competition_data.competition.slug == 'advent-of-code' ? '' : ' '+props.competition_data.competition.title} ${props.competition_data.year} on CompetitiveCoders.com!`}
                />
            </Helmet>

        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} component={'main'}>

            <ContestBreadcrumbs organization={props.competition_data.organization}
                                competition={props.competition_data.competition} competition_round={null}
                                year={props.competition_data.year}
                                problem={null} />


            <CompetitionHeader competition_round={null} competition={props.competition_data.competition}
                               organization={props.competition_data.organization} year={props.competition_data.year} />

            {props.competition_data.competition.slug == 'coding-practice-with-kick-start' ?
                <CompetitionWelcomeMedia youtube_video_id={'ys2akzpjl3k'} />
                :
                <></>
            }

            <List component='nav' aria-label='Rounds' dense={true}>
                {props.competition_data.rounds.map((c) => (
                        <ListItem key={c.slug} button dense={true} component={RouterLink} to={baseUrl + c.slug}>
                            <ListItemIcon>
                                {new Date(c.start_time) > new Date() ?
                                <UpdateIcon />
                                    :
                                    <StarIcon  />
                                }

                            </ListItemIcon>
                            <ListItemText className={new Date(c.start_time) > new Date() ? classes.grayed : classes.normal} primary={c.title} secondary={c.start_time.toLocaleString()}  />

                        </ListItem>
                ))}

            </List>
            <br />
            <hr />
            <br />


            <ProblemContentLoader organization_slug={props.competition_data.organization.slug}
                                  competition_slug={props.competition_data.competition.slug}
                                  year={props.competition_data.year.toString()}
                                  round_slug={''}
                                  problem_slug={''}
                                  limit={20}
                                  programming_language_filter={[]}
            />


        </Grid>
    </>;

};