import React from 'react';
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Help, School, Subject } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: 'inherit',
            textDecoration: 'none',
        },

        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: 'block',
            height: 30,
            [theme.breakpoints.up('sm')]: {
                display: 'block',
                height: 40,
            },
        }, collapsedTitle: {
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        verticalAlign: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    }),
);

export default function TopHeader() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >

            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label='show 4 new mails' color='inherit'>
                    <Badge badgeContent={4} color='secondary'>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label='show 11 new notifications' color='inherit'>
                    <Badge badgeContent={11} color='secondary'>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='primary-search-account-menu'
                    aria-haspopup='true'
                    color='inherit'
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position='static'>
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">*/}
                    {/*    <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <div className={classes.verticalAlign}>
                        <Link to='/' className={classes.link}>
                            <img className={classes.title} src={'/logo-white.png'} alt={'CompetitiveCoders.com'} />

                            {/*<Typography className={classes.title} variant="h6" noWrap>*/}
                            {/*    Competitive coders [BETA]*/}
                            {/*</Typography>*/}
                            {/*<Typography className={classes.collapsedTitle} variant="h6" noWrap>*/}
                            {/*    Home*/}
                            {/*</Typography>*/}
                        </Link>
                    </div>
                    &nbsp;
                    <MenuItem> <Link to={`/calendar/${new Date().getFullYear()}`} className={classes.link}
                                     style={{ marginTop: 10 }}>
                        <div className={classes.sectionMobile}><EventIcon  titleAccess={'Calendar'} />
                        </div>
                        <div className={classes.sectionDesktop}>Calendar</div>
                    </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to='/ProgrammingCompetitions' className={classes.link} style={{ marginTop: 10 }}>
                            <div className={classes.sectionMobile}><EmojiEventsIcon
                                                                                    titleAccess={'Competitions'} />
                            </div>
                            <div className={classes.sectionDesktop}>Competitions</div>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to='/Resources' className={classes.link} style={{ marginTop: 10 }}>
                            <div className={classes.sectionMobile}><School
                                                                                    titleAccess={'Learning'} />
                            </div>
                            <div className={classes.sectionDesktop}>Resources</div>
                        </Link>
                    </MenuItem>
                    {/*<MenuItem> <Link to="/Algorithms/" className={classes.link}>Algorithms</Link> </MenuItem>*/}
                    <MenuItem>
                        <Link to='/blog/' className={classes.link} style={{ marginTop: 10 }}>
                            <div className={classes.sectionMobile}><Subject
                                                                           titleAccess={'Blog'} />
                            </div>
                            <div className={classes.sectionDesktop}>Blog</div>
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to='/About' className={classes.link} style={{ marginTop: 10 }}>
                            <div className={classes.sectionMobile}><Help
                                                                           titleAccess={'About'} />
                            </div>
                            <div className={classes.sectionDesktop}>About</div>
                        </Link>
                    </MenuItem>
                    {/*<div className={classes.search}>*/}
                    {/*    <div className={classes.searchIcon}>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </div>*/}
                    {/*    <InputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        classes={{*/}
                    {/*            root: classes.inputRoot,*/}
                    {/*            input: classes.inputInput,*/}
                    {/*        }}*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>

                        {/*    <IconButton aria-label="show 4 new mails" color="inherit">*/}
                        {/*        <Badge badgeContent={4} color="secondary">*/}
                        {/*            <MailIcon />*/}
                        {/*        </Badge>*/}
                        {/*    </IconButton>*/}
                        {/*    <IconButton aria-label="show 17 new notifications" color="inherit">*/}
                        {/*        <Badge badgeContent={17} color="secondary">*/}
                        {/*            <NotificationsIcon />*/}
                        {/*        </Badge>*/}
                        {/*    </IconButton>*/}
                        {/*    <IconButton*/}
                        {/*        edge="end"*/}
                        {/*        aria-label="account of current user"*/}
                        {/*        aria-controls={menuId}*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        onClick={handleProfileMenuOpen}*/}
                        {/*        color="inherit"*/}
                        {/*    >*/}
                        {/*        <AccountCircle />*/}
                        {/*    </IconButton>*/}
                    </div>
                    {/*<div className={classes.sectionMobile}>*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="show more"*/}
                    {/*        aria-controls={mobileMenuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleMobileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}
                    {/*        <MoreIcon />*/}
                    {/*    </IconButton>*/}
                    {/*</div>*/}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}
