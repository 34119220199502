import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { FC } from 'react';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(4),
        },

    }),
);

export const CompetitionHeaderSkeleton: FC = () => {
    const classes = useStyles();

    return <div className={classes.root}>
        <Box>
            <Skeleton animation='wave' height={60} width='60%' />
        </Box>
        <Skeleton animation='wave' height={40} width='40%' />


        <Skeleton animation='wave' height={20} width='20%' />
    </div>;
}