import { Card, CardHeader, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { YoutubeAttachment } from '../../../datatypes/feed-interfaces';
import { Container } from '@material-ui/core';

interface Props {
    attachment: YoutubeAttachment,

}


const baseUrl = 'https://www.youtube-nocookie.com/embed/';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%',

            // flexGrow: 1,
            // height: '100%',
            // padding: 0,
            // '&:last-child': {
            //     paddingBottom: 0,
            // },
            // border: '2px solid gray',
        },
        video: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    }),
);


export const YoutubeAttachmentPanel: React.FC<Props> = ({

                                                            attachment,
                                                        }) => {
// export const YouTubePost = ({videoId, height}: Props) => {
    const classes = useStyles();
    const videoUrl = baseUrl + attachment.video_id;


    return (
        // <div className={classes.root} style={{ height: videoHeight }}>
        // <Container>
            <div className={classes.root}>
                <iframe
                    src={videoUrl}
                    width={'100%'}
                    height={'100%'}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope;'
                    allowFullScreen
                    className={classes.video}
                ></iframe>

            </div>
        // </Container>
    );
};

// export default YoutTubePost
