import { Box, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        sticky: {
            position: 'fixed',
            maxWidth: 270,
        },
        filterbox: {
            padding: 10,
            borderRadius: 2,
            backgroundColor: '#1D1E26',
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',

        },
        languageChip: {
            backgroundColor: '#383A58',
            color: 'inherit',
            '&:hover': {
                color: '#FB9583',
                backgroundColor: '#383A58',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },

        },
        languageChipSelected: {
            backgroundColor: '#383A58',
            color: '#FB9583',
            '&:hover': {
                backgroundColor: '#383A58',
                color: 'white',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },

        },
    }),
);

interface Props {
    selected_languages: string[],
    callbackFn: (language: string) => void,
    sticky: boolean,
    show_title: boolean
}

export const ProgrammingLanguageFilterBox: FC<Props> = (props) => {

    const classes = useStyles();

    const filterable_programming_languages = [
        'Python',
        'C++',
        'Rust',
        'Java',
        'JavaScript',
        'R',
        'Haskell',
        'F#',
        'Kotlin',
        'TypeScript',
        'Go',
        'C#',
        'C',
        'Scala',
        'Swift',
        'PHP',
        'F*',
        'Elixir',
        'Ruby',
        'Clojure',
        'Julia',
        'Lua',
        'Lisp',
        'SQL',
        'Assembly',
        'Erlang',
        'Perl',
        'OCaml',
        'Bash',
        'Cobol',
        'Dart',
        'Snap',
        'Elm',
        'Excel',
        '<Unknown>',
    ];

    return <Box component={'aside'} className={`${classes.filterbox} ${props.sticky ? classes.sticky : ''}`}>

        {props.show_title ?
            <span>
                <Typography variant={'h4'} style={{ color: '#FB9583' }}>.filter()</Typography>
                <br />
            </span>
            : null
        }

        <Typography variant={'h6'}>Programming language</Typography>


        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, marginTop: 10, marginBottom: 15 }}>
            {filterable_programming_languages.map(l => <div key={'plf-' + l} onClick={() => props.callbackFn(l)}>
                    <Chip
                        className={props.selected_languages.findIndex(s => s == l) > -1 ? classes.languageChipSelected : classes.languageChip}
                        label={l} size={'small'} />
                </div>,
            )}

        </div>
    </Box>;
};