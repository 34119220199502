import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import {
    createStyles, Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import { CompetitionLink, CompetitionOrganizationLink } from '../../datatypes/competition-round-view-data';
import { CompetitionHeader } from './CompetitionHeader';
import { ProblemContentLoader } from './ProblemContentLoader';
import Typography from '@material-ui/core/Typography';


export interface CompetitionViewData {
    competition: CompetitionLink,
    organization: CompetitionOrganizationLink
    years: number[]
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // width: '100%',
            // maxWidth: 360,
            //   backgroundColor: theme.palette.background.paper,
        },

        problemListItem: {
            // border: '1px solid black',
            padding: 20,
            margin: 10,
            borderRadius: 10,
            boxShadow: '1px 1px 4px #414557',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                backgroundColor: '#414557',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },
        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2,

        },

    }),
);


interface Props {
    competition_data: CompetitionViewData;
}

export const CompetitionView: FC<Props> = (props) => {
    const baseUrl = `/ProgrammingCompetitions/${props.competition_data.organization.slug}/${props.competition_data.competition.slug}/`;
    const classes = useStyles();
    return <>
        <Helmet>
            <title>{`${props.competition_data.organization.title}${props.competition_data.competition.slug == 'advent-of-code' ? '' : ' ' + props.competition_data.competition.title} solutions`}</title>
            <meta
                name='description'
                content={`See solutions and walkthroughs of ${props.competition_data.organization.title}${props.competition_data.competition.slug == 'advent-of-code' ? '' : ' ' + props.competition_data.competition.title} on CompetitiveCoders.com!`}
            />
        </Helmet>
        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} component={'main'}>

            <ContestBreadcrumbs organization={props.competition_data.organization}
                                competition={props.competition_data.competition} competition_round={null}
                                year={null}
                                problem={null} />


            <CompetitionHeader competition_round={null} competition={props.competition_data.competition}
                               organization={props.competition_data.organization} year={null} />
            <br />

            <Typography variant={'h4'}>Goto year:</Typography>
            {/*<List component="nav"  aria-label="Rounds" dense={true}>*/}
            <Grid container xs={12} spacing={1} style={{ marginTop: 10 }}>
                {props.competition_data.years.map((c) => (
                    <Grid key={`compyear-${c}`} item xs={4} md={2}>
                        <Link component={RouterLink} to={baseUrl + c} color={'inherit'}>
                            <div key={c} className={classes.problemListItem}>

                                <Typography style={{ textAlign: 'center' }}>{c}</Typography>

                            </div>
                        </Link>
                    </Grid>
                ))}

            </Grid>
            {/*</List>*/}

            <br />
            <Divider variant={'middle'} />
            <br />

            {props.competition_data.competition.slug == 'advent-of-code' ?
                <div style={{ padding: 20 }}>

                    <Typography variant={'h6'}>What is Advent of Code?</Typography>
                    <Typography variant={'body2'}>
                        Advent of Code is an annual coding event in which participants solve a series of programming
                        puzzles.
                        It is held online and runs throughout the month of December (duh!) where a new puzzle is
                        released each day.
                        The puzzles contain two parts and the second part is revealed after you solved the first one. It
                        usually contains some twist that trips up your solution from part one.
                        The Participants use their programming skills to solve the puzzles and earn stars - one star per
                        part.
                        <br />
                        <br />
                        The event is open to people of all skill levels, and many people participate as a way to
                        practice their coding skills, learn new programming languages and compete with others.
                        <br />
                        <br />
                        Advent of Code is a more &apos;friendly&apos; programming competition and is accessible to
                        everyone from beginners to seasoned programming veterans.
                        It is however extremely difficult to end up on the global leaderboard - where only the 100 first
                        people to solve the daily puzzle ends up.
                    </Typography>
                    <br />
                    <Divider variant={'middle'} />
                    <br />
                </div> : null
            }

            {props.competition_data.competition.slug == 'code-jam' ?
                <div style={{ padding: 20 }}>

                    <Typography variant={'h6'}>What is <strong>Google Code jam</strong>?</Typography>
                    <Typography variant={'body2'}>
                        Google Code Jam is a programming competition held annually by Google. It is one of the
                        world&apos;s
                        largest and most renowned competitive coding contests, drawing competitors from all around the
                        world. Participants in the competition are given a series of challenges to tackle using their
                        programming abilities and expertise. The objective is to solve the problems as quickly and
                        effectively as possible by employing various algorithms in your choice of programming language.
                        The competition&apos;s best performers get awards and recognition from Google and the
                        programming
                        community.
                    </Typography>
                    <br />
                    <Divider variant={'middle'} />
                    <br />
                </div> : null
            }

            {props.competition_data.competition.slug == 'kick-start' ?
                <div style={{ padding: 20 }}>

                    <Typography variant={'h6'}>What is <strong>Google Kick start</strong>?</Typography>
                    <Typography variant={'body2'}>
                        Google Kickstart is an annual programming contest consisting of several independent rounds. It
                        is made to support aspirant computer scientists in honing their abilities and promoting their
                        careers. The competition provides a variety of problems to solve and is available to
                        professionals and students alike from all around the world. Participants are expected to use a
                        range of techniques and programming languages to tackle the challenges as quickly and
                        effectively as they can. Google and the programming community give rewards and recognition to
                        the competition&apos;s best finishers.

                    </Typography>
                    <br />
                    <Divider variant={'middle'} />
                    <br />
                </div> : null
            }

            {props.competition_data.competition.slug == 'hacker-cup' ?
                <div style={{ padding: 20 }}>

                    <Typography variant={'h6'}>What is <strong>Meta Hacker cup</strong>?</Typography>
                    <Typography variant={'body2'}>
                        Meta hosts an annual competitive programming competition called the Meta Hacker Cup. It is one
                        of the world&apos;s largest and most prominent programming competitions, attracting competitors from
                        a wide range of backgrounds and skill levels. Participants in the coding contest are given a
                        series of challenges to tackle using their programming abilities and expertise. The objective is
                        to solve the problems as fast and efficiently as possible by applying efficient algorithms. In
                        contrast to most other competitions where there is a limited set of programming languages and
                        versions to choose from, you can choose any language and tool you want in Meta Hacker Cup. The
                        competition&apos;s best performers get awards and recognition from Meta and the programming
                        community. The competition was formerly known as <strong>Facebook Hacker Cup</strong> until 2022.

                    </Typography>
                    <br />
                    <Divider variant={'middle'} />
                    <br />
                </div> : null
            }


            <ProblemContentLoader organization_slug={props.competition_data.organization.slug}
                                  competition_slug={props.competition_data.competition.slug}
                                  year={''}
                                  round_slug={''}
                                  problem_slug={''}
                                  limit={20}
                                  programming_language_filter={[]}
            />


        </Grid>
    </>;

};
;