import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },

    }),
);

export const NewsletterForm: FC = () => {
    return <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <iframe style={{ marginLeft: 'auto', marginRight: 'auto' }} frameBorder={0} scrolling='no'
                height='650px' width='340'
                src='https://cdn.forms-content.sg-form.com/746fbeb5-6978-11ed-ad66-26a35c2f55d0' />
    </div>;
}

export const NewsLetter: FC = () => {


    const classes = useStyles();


    return <div className={classes.root} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <NewsletterForm />
    </div>;
}