import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, createStyles, Dialog, DialogTitle, Link, makeStyles, Theme } from '@material-ui/core';
import { Email, EmailOutlined } from '@material-ui/icons';
import { NewsletterForm } from '../Newsletter';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // color: '#444'
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            border: '1px dashed rgba(255, 255, 255, 0.5)',
            borderRadius: 4,
            padding: 10,
            textAlign: 'center',
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
        },

    }),
);


export const NewsLetterWidget: FC = () => {

    const [showDialog, setShowDialog] = useState(false);

    const classes = useStyles();

    return <div className={classes.root}>

        <Typography variant={'h5'} color={'textSecondary'}>Get in the know!</Typography>
        <br />
        <Typography variant={'body1'} color={'textSecondary'} onClick={() => setShowDialog(true)}>
            Your ideas are intriguing to me, and I wish to subscribe to your <strong style={{color: 'white'}}>newsletter</strong>!
        </Typography>
        <br />
        <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>

            <div className={classes.sectionDesktop}>
                <Button variant={'contained'}
                        onClick={() => setShowDialog(true)}
                        startIcon={<EmailOutlined />}
                        style={{ backgroundColor: '#FD78C5', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    I&apos;m in!
                </Button>
            </div>            <div className={classes.sectionMobile}>
                <Button
                        href={'https://cdn.forms-content.sg-form.com/746fbeb5-6978-11ed-ad66-26a35c2f55d0'}
                        target={'_blank'}
                        variant={'contained'}
                        startIcon={<EmailOutlined />}
                        style={{ backgroundColor: '#FD78C5', marginLeft: 'auto', marginRight: 'auto' }}
                >

                    I&apos;m in!
                </Button>
            </div>
        </div>

        <Dialog onClose={() => setShowDialog(false)} aria-labelledby='newsletter-dialog-title' open={showDialog}
                fullWidth={true} maxWidth={'lg'}>
            {/*<DialogTitle id='newsletter-dialog-title'><Email/> We&apos;ve gotz Newsletter!</DialogTitle>*/}
            <NewsletterForm />
        </Dialog>

    </div>;
};