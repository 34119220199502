import React, { FC } from 'react';
import { FeedItemJustFinished } from './FeedItemJustFinished';
import { useQuery } from 'react-query';
import { fetchContestRounds } from '../../data/repository';
import { CompetitionLinksWidgetListSkeleton } from '../../widgets/CompetitionLinksWidgetListSkeleton';
import { CompetitionLinksWidgetList } from '../../widgets/CompetitionLinksWidgetList';
import { FeedSkeleton } from './FeedSkeleton';
import { Soft404Widget } from '../Competitions/Soft404Widget';


export const FeedItemJustFinishedLoader:FC = () => {
    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    // return <ProblemContentSkeleton />;

    const { isLoading, error, status, data } =
        useQuery(['contests', {
                source: 'recent',
                elements: 1,
            }], () => fetchContestRounds('recent', 1)
            , { staleTime: 600000 });

    if (isLoading) return (
        <>
            <FeedSkeleton/>
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <FeedItemJustFinished competition_round={data[0]} /> :
        <div>&quot unknown &quot data received :(</div>;
};