import {
    makeStyles,
    Theme,
    createStyles,
    Typography,
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Chip, Link,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { Link as RouterLink } from 'react-router-dom';
import { CompetitionRoundLink, CompetitionRoundViewData } from '../../datatypes/competition-round-view-data';
import { Helmet } from 'react-helmet';


interface Props {
    year: number,
    rounds: CompetitionRoundViewData[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 20,
        },
        header: {
            // backgroundColor:  theme.palette.background.paper,
            paddingBottom: 5,
            borderRadius: 2,
        },
        content: {
            // padding: 10,
            border: '1px solid #444444',
            borderRadius: 2,
            boxShadow: '1px 1px 1px rgba(10, 10, 10, .3)',
            marginBottom: 20,
        },
        inline: {
            display: 'inline',
        },
    }),
);

export const Calendar: FC<Props> = (props: Props) => {


    const classes = useStyles();


    const [showAll, setShowAll] = useState(false);

    const data = new Map<number, CompetitionRoundViewData[]>();

    for (let month = (showAll || props.year != new Date().getFullYear()) ? 0 : new Date().getMonth(); month <= 12; month++) {

        data.set(month, props.rounds.filter(r => r.start_time.getMonth() == month));

    }
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const iconmap = new Map<string, string>([['google', 'google-icon.ico'], ['meta', 'fb-icon.png'], ['leetcode', 'leetcode-icon.ico'], ['advent-of-code', 'aoc-icon.png'], ['consid', 'considition.png']]);

    return (

        <main className={classes.root}>

            <Helmet>
                <title>{`The programming competitions and coding contests calendar ${props.year}`}</title>
                <meta
                    name="description"
                    content={`Find coding contests to join or find out about competitive programming events in the past in the competitive coders programming competitions calendar for ${props.year}! `}
                />

            </Helmet>


            <Typography variant={'h4'} component={'h1'}>Programming competition calendar {props.year}</Typography>
<br/>
            { props.year == new Date().getFullYear() ?
            <div style={{ float: 'right' }}>
                <Link style={{ cursor: 'default' }} onClick={() => setShowAll(!showAll)}
                      title={showAll ? 'Only see coming' : 'See whole year'}>{showAll ? 'Only see coming' : 'See whole year'}</Link>
            </div>
            : null
            }

            {Array.from(data.keys()).map(monthnum =>
                <>
                    {data.get(monthnum) != undefined && data.get(monthnum)!.length > 0 ?

                        <>
                            <div className={classes.header}>
                                <Typography variant='h6' component='h2' color='primary'>
                                    {monthNames[monthnum]} {props.year}
                                </Typography>
                            </div>
                            <div className={classes.content}>
                                <List dense={true}>

                                    {data.get(monthnum)?.sort((r1,r2) => r1.start_time<r2.start_time ? -1 : 1).map(round =>
                                        <>

                                            <ListItem button alignItems='flex-start' component={RouterLink}
                                                      to={`/ProgrammingCompetitions/${round.organization.slug}/${round.competition.slug}/${round.start_time.getFullYear()}/${round.slug}`}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={`${round.organization.title} ${round.competition.title} ${props.year} - ${round.title}`}
                                                        src={`/logos/${iconmap.get(round.organization.slug)}`}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={round.start_time.toLocaleString()}
                                                    secondary={
                                                        <React.Fragment>

                                                            <Typography
                                                                component='p'
                                                                variant='h6'
                                                                className={classes.inline}
                                                                color='textPrimary'
                                                            >
                                                                {`${round.organization.title} ${round.competition.title} ${props.year} - ${round.title}`}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <Chip icon={<AccessAlarmIcon />}
                                                          label={`${Math.floor((round.end_time.getTime() - round.start_time.getTime()) / 36e5)}h`} /> &nbsp;
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider variant='inset' component='li' />

                                        </>)}
                                </List>
                            </div>
                        </>
                        : <></>}

                </>)}

        </main>
    );
};