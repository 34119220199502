import React, { FC } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';

import { CompetitionOrganizationView, CompetitionOrganizationViewData } from './CompetitionOrganizationView';
import { CompetitionOrganizationList } from './CompetitionOrganizationList';
import { CompetitionRoundViewLoader } from './CompetitionRoundViewLoader';
import { CompetitionYearViewLoader } from './CompetitionYearViewLoader';
import { CompetitionViewLoader } from './CompetitionViewLoader';
import { CompetitionOrganizationViewLoader } from './CompetitionOrganizationViewLoader';
import { CompetitionProblemViewLoader } from './CompetitionProblemViewLoader';

interface RouteParams {
    organization: string,
    competition: string,
    year: string,
    round: string,
    problem: string
}


type Props = RouteComponentProps<RouteParams>


const ProgrammingCompetitionsLoader: FC<Props> = (props) => {

    const params = useParams<RouteParams>();


    if (params.problem) {

        return  <CompetitionProblemViewLoader competition_round_slug={params.round} competition_slug={params.competition} organization_slug={params.organization} year={params.year} problem_slug={params.problem}/>

    }else if (params.round) {

        return  <CompetitionRoundViewLoader competition_round_slug={params.round} competition_slug={params.competition} organization_slug={params.organization} year={params.year}/>

    } else if (params.year) {
        return <CompetitionYearViewLoader competition_slug={params.competition} competition_year={params.year} organization_slug={params.organization} />;

    } else if (params.competition) {

        return  <CompetitionViewLoader competition_slug={params.competition} organization_slug={params.organization}/>;

    } else if (params.organization) {

        return <CompetitionOrganizationViewLoader organization_slug={params.organization} /> ;
    } else {
        return <CompetitionOrganizationList />;
    }

};

export default ProgrammingCompetitionsLoader;
