import React, { FC } from 'react';
import { Button, createStyles, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AlarmIcon from '@material-ui/icons/Alarm';
import { CompetitionRoundViewData } from '../../datatypes/competition-round-view-data';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns'

interface Props {
    competition_round: CompetitionRoundViewData;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            paddingTop:10,
            backgroundColor: '#FB9583',
            boxShadow: '4px 4px 4px rgba(10, 10, 10, .3)',
            marginBottom: theme.spacing(2),
            borderRadius: 10,
        },
        verticallyCentered: {
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    }),
);


export const FeedItemNextCompetition: FC<Props> = (props: Props) => {
    const classes = useStyles();
    const round_url = `/ProgrammingCompetitions/${props.competition_round.organization.slug}/${props.competition_round.competition.slug}/${props.competition_round.year}/${props.competition_round.slug}`;

    const roundCaption = `${props.competition_round.organization.slug == "advent-of-code" ? "" : props.competition_round.organization.title} ${props.competition_round.competition.title} ${props.competition_round.organization.slug == 'leetcode' ? '#' : props.competition_round.year + ' - '}${props.competition_round.title}`;
    return <div
        className={classes.root}>
        <Grid container spacing={1}>
            <Grid item sm={12} xl={2} md={2} xs={12} lg={12} className={classes.verticallyCentered}>
                <AlarmIcon fontSize={'large'} style={{ marginRight: 10 }} />
                <Typography variant={'button'} className={classes.verticallyCentered}>Next up:</Typography>
            </Grid>
            <Grid item sm={12} xl={6} md={6} xs={12} lg={12} className={classes.verticallyCentered}>
                <Link component={RouterLink}
                      to={round_url}
                      color={'inherit'}>
                    <div>
                        <Typography
                            variant={'h6'}>{roundCaption}</Typography>
                    </div>
                </Link>
            </Grid>

            <Grid item sm={8} xl={3} md={3} xs={8} lg={8} className={classes.verticallyCentered}>
                {/*{props.competition_round.start_time.toString()}*/}
                { format(props.competition_round.start_time, "yyyy-MM-dd HH:mm")}

            </Grid>

            <Grid item sm={4} xl={1} md={1} xs={4} lg={4} className={classes.verticallyCentered}>

                <Button
                    style={{ marginLeft: 'auto' }}
                    variant='outlined'
                    size={'small'}
                    color='inherit'
                    href={props.competition_round.external_url}
                    target='_blank'
                    endIcon={<OpenInNewIcon />}
                >
                    Open
                </Button>

            </Grid>
        </Grid>

    </div>;
};

