import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { fetchContestRoundbySlug, fetchContestRoundsByYear } from '../../data/repository';
import { CompetitionRoundViewSkeleton } from './CompetitionRoundViewSkeleton';
import CompetitionRoundView from './CompetitionRoundView';
import { CompetitionYearViewSkeleton } from './CompetitionYearViewSkeleton';
import { CompetitionYearView } from './CompetitionYearView';
import { Soft404Widget } from './Soft404Widget';

interface Props {
    competition_slug: string,
    organization_slug: string,
    competition_year: string
}

export const CompetitionYearViewLoader: FC<Props> = (props) => {


    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    // return <ProblemContentSkeleton />;

    const { isLoading, error, status, data } =
        useQuery(['competitionyear', {
            competition_slug: props.competition_slug,
            year: props.competition_year,
            organization_slug: props.organization_slug,
        }], () => fetchContestRoundsByYear(props.competition_slug, parseInt(props.competition_year), props.organization_slug),
            { staleTime: 600000 });

    if (isLoading) return (
        <CompetitionYearViewSkeleton />
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <CompetitionYearView competition_data={data} /> : <div>&quot unknown &quot data received :(</div>;

};