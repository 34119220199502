import React, { FC, useState } from 'react';
import { Feed } from './Feed';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { fetchExternalContent } from '../../data/repository';
import { FeedSkeleton } from './FeedSkeleton';
import { Soft404Widget } from '../Competitions/Soft404Widget';


interface Props {
    feedsource: 'main' | 'user' | 'competition',
    sourceId: string
}

export const FeedLoader: FC<Props> = ({ feedsource, sourceId }: Props) => {

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    const { isLoading, error, status, data } =
        useQuery(['feed', {
            sourceId: sourceId,
            feedsource: feedsource,
        }], () => fetchExternalContent(feedsource),
            { staleTime: 300000 });

    if (isLoading) return (
        <>
            <FeedSkeleton />
            <FeedSkeleton />
            <FeedSkeleton />
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return (data ? <Feed feedItems={data} /> : <div>&quot unknown &quot data received</div>);
};
