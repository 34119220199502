import { Breadcrumbs, createStyles, Divider, Link, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC, useContext } from 'react';
import {
    CompetitionRoundViewData,
    CompetitionOrganizationData,
    CompetitionData,
    CompetitionOrganizationLink,
    CompetitionLink,
    CompetitionRoundLink,
    CompetitionRoundProblemLink,

} from '../../datatypes/competition-round-view-data';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { AddBookmark, BookmarkItem, GetBookmarks, IsBookmarked, RemoveBookmark } from '../../utils/storage-repository';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { BookmarksContext } from '../../utils/bookmark-context';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // width: '100%',
            // maxWidth: 360,
            //   backgroundColor: theme.palette.background.paper,
        },
        link: {
            display: 'flex',
        },
        dividermargin: {
            marginBottom: 15,
        },
        bookmarkIcon: {
            marginLeft: 10,


            boxShadow: '2px 2px 5px #1c1c1c',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                // backgroundColor: "#505050",
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },
        },
    }),
);


interface Props {
    organization: CompetitionOrganizationLink,
    competition: CompetitionLink | null,
    competition_round: CompetitionRoundLink | null,
    year: number | null,
    problem: CompetitionRoundProblemLink | null,
}

export const ContestBreadcrumbs: FC<Props> = (props) => {
    const classes = useStyles();


    const { bookmarks, setBookmarks } = useContext(BookmarksContext);

    let bookmark_url = '/ProgrammingCompetitions/';
    let bookmark_title = 'Competitions';

    if (props.organization) {
        bookmark_url += `${props.organization.slug}/`;
        bookmark_title = props.organization.title;
    }

    if (props.competition) {
        bookmark_url += `${props.competition.slug}/`;
        bookmark_title = `${bookmark_title} ${props.competition.title}`;
    }

    if (props.year) {
        bookmark_url += `${props.year}/`;
        bookmark_title = `${bookmark_title} ${props.year}`;
    }

    if (props.competition_round) {
        bookmark_url += `${props.competition_round.slug}/`;
        bookmark_title = `${bookmark_title} ${props.competition_round.title}`;
    }

    if (props.problem) {
        bookmark_url += `${props.problem.slug}/`;
        bookmark_title = `${bookmark_title} ${props.problem.title}`;
    }

    const bookmark_item: BookmarkItem = { title: bookmark_title, url: bookmark_url };

    const is_bookmarked = bookmarks && bookmarks.findIndex(b => b.url === bookmark_url) > -1;


    function bookmarkClick() {


        if (is_bookmarked) {
            RemoveBookmark(bookmark_item);
            setBookmarks(GetBookmarks());
        } else {

            AddBookmark(bookmark_item);
            setBookmarks(GetBookmarks());
        }
    }

    return <div style={{ display: 'flex' }}>

        <Breadcrumbs aria-label='breadcrumb'>

            <Link color='inherit' href={`/ProgrammingCompetitions/`} className={classes.link}
                  variant={'overline'}>
                Competitions
            </Link>

            {props.organization.slug != 'advent-of-code' ?
                <Link color='inherit' href={`/ProgrammingCompetitions/${props.organization.slug}`}
                      className={classes.link}
                      variant={'overline'}>
                    {/*<LanguageIcon className={classes.icon} />*/}
                    {props.organization.title}
                </Link>
                : null}

            {props.competition != null ?
                <Link color='inherit'
                      href={`/ProgrammingCompetitions/${props.organization.slug}/${props.competition.slug}`}
                      variant={'overline'}>
                    {/*<CategoryIcon className={classes.icon} />*/}
                    {props.competition.title}
                </Link> : null
            }

            {props.year != null && props.competition != null ?
                <Link color='inherit'
                      href={`/ProgrammingCompetitions/${props.organization.slug}/${props.competition.slug}/${props.year}`}
                      variant={'overline'}>
                    {/*<CategoryIcon className={classes.icon} />*/}
                    {props.year}
                </Link>
                : null
            }

            {props.competition_round != undefined ?

                <Link color='inherit'
                      href={`/ProgrammingCompetitions/${props.organization.slug}/${props.competition!.slug}/${props.year}/${props.competition_round.slug}`}
                      variant={'overline'}>

                    {props.competition_round.title}</Link>

                : null
            }

            {props.problem != undefined ?


                <Typography color='textPrimary' variant={'overline'}>
                    {/*<EventIcon className={classes.icon} />*/}
                    {props.problem.title}</Typography>

                : null
            }


        </Breadcrumbs>
        <div title={is_bookmarked ? 'Bookmarked' : 'Bookmark'} onClick={() => bookmarkClick()}>
            {is_bookmarked ? <BookmarkIcon color={'primary'} className={classes.bookmarkIcon} /> :
                <BookmarkBorderIcon className={classes.bookmarkIcon} />}
        </div>
        <br />
        <Divider className={classes.dividermargin} />
    </div>;
};