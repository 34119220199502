import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Skeleton from '@material-ui/lab/Skeleton';
import CardContent from '@material-ui/core/CardContent';
import {
    Chip,
    createStyles,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ProblemContentCard } from './ProblemContentCard';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import CardMedia from '@material-ui/core/CardMedia';
import LinkIcon from '@material-ui/icons/Link';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },

        verticallyCentered: {
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        tripleHeader: {
            display: 'flex',
            padding: theme.spacing(),
            paddingBottom: 0,
            marginBottom: theme.spacing(),

        },
        headerMiddle: {
            flexGrow: 1,
            marginLeft: 10,
            marginTop: 'auto',
            marginBottom: 'auto',

        },
        media: {
            height: 120,
            width: '100%',
            // paddingTop: '56.25%', // 16:9
        },
        horizontalitem: {
            marginRight: theme.spacing(1),
        },
    }));

export const ProblemContentSkeleton: FC = () => {
    const classes = useStyles();

    return <CardContent className={classes.root}>
        <Grid container xs={12} spacing={2}>
            <Grid container xs={9} spacing={2}>

                {[1, 2, 3].map(c =>


                    <Grid key={`cc${c}`} item xs={12} md={4}>

                        <Card raised={true}>

                            <div className={classes.tripleHeader}>
                                <div className={classes.verticallyCentered}>
                                    <Skeleton animation='wave' variant='circle' width={20} height={20} />
                                </div>
                                <div className={classes.headerMiddle}>
                                    <Typography variant={'overline'} component={'h2'}
                                                color={'inherit'}>
                                        <Skeleton animation='wave' height={10} width='100%'
                                                  style={{ marginBottom: 6 }} />
                                    </Typography>

                                </div>
                                <div className={classes.verticallyCentered}>
                                    {/*<Chip size='small'*/}
                                    {/*                                              variant='outlined'*/}
                                    {/*                                              color='default'*/}
                                    {/*                                              icon={<CodeIcon />}*/}
                                    {/*                                              label='N/A' />*/}
                                </div>
                            </div>

                            <Skeleton animation='wave' variant='rect' className={classes.media} />

                            <CardContent>
                                <Skeleton animation='wave' variant='circle' width={40} height={40} /> <Skeleton
                                animation='wave' height={10} width='80%' style={{ marginBottom: 6 }} />
                            </CardContent>
                        </Card>
                        {/*</Paper>*/}
                    </Grid>,
                )
                }


            </Grid>
            <Grid container xs={3} spacing={0}>
                <div style={{ marginLeft: '12px', width: '100%' }}>
                    <List dense={true}>
                        {[1, 2, 3].map(c =>
                        <ListItem key={`ccgh${c}`}>
                            <ListItemIcon style={{ minWidth: '36px' }}><GitHubIcon /></ListItemIcon>
                            <ListItemText primary={<Skeleton animation='wave' height={10} width='100%'
                                                             style={{ marginBottom: 6 }} />}
                                          secondary={<Skeleton animation='wave' height={10} width='100%'
                                                               style={{ marginBottom: 6 }} />} />

                        </ListItem>
                            )}



                    </List>
                </div>
            </Grid>
        </Grid>
    </CardContent>;

};