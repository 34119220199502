import React, { ReactElement, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import {
    createStyles,
    createTheme,
    makeStyles,
    responsiveFontSizes,
    Theme,
    ThemeProvider,
} from '@material-ui/core/styles';
import { Container, CssBaseline, Grid } from '@material-ui/core';
import TopHeader from './components/TopHeader';
import Footer from './components/Footer';
import LeftColumn from './components/LeftColumn';
import RightColumn from './components/RightColumn';
import ProgrammingCompetitionsLoader from './components/Competitions/ProgrammingCompetitionsLoader';
import { FeedLoader } from './components/Feed/FeedLoader';
import { QueryClient, QueryClientProvider } from 'react-query';
import { About } from './About';
import { GetBookmarks } from './utils/storage-repository';
import { BookmarksContext } from './utils/bookmark-context';
import { LearningPage } from './LearningPage';
import { Soft404Widget } from './components/Competitions/Soft404Widget';
import { CalendarWrapper } from './CalendarWrapper';
import { CalendarLoader } from './CalendarLoader';
import { Resources } from './Resources';


const darkTheme: Theme = createTheme({
    typography: {
        fontFamily: '\'Fira Code\', monospace;',
        // fontFamily: "'Shippori Antique B1', sans-serif",
        // fontFamily: "'Roboto', sans-serif",
        button:
            {
                textTransform: 'none',
            },
    },
    palette: {
        type: 'dark',
        primary: {
            // main: '#FCA63E',
            main: '#FDCA86',
        },
        secondary: {
            // main: '#4F9FB4',
            main: '#64b5f6',
            // main: '#90ee90',
        },

        background: {
            default: '#15171B',
            paper: '#383A58',

        },
    },
    props: {
        MuiAppBar: {
            color: 'transparent',
        },
    },
    shape: {
        borderRadius: 5,
    },
    spacing: 8,
});

let theme: Theme = createTheme(darkTheme);
theme = responsiveFontSizes(theme);


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            // flexGrow: 1,
            marginTop: theme.spacing(2),

            backgroundColor: '#15171B',

        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 4,


        },
        leftColumn: {
            // boxShadow: '2px 2px 5px #1c1c1c',
        },

        paper: {
            // padding: theme.spacing(2),
            // textAlign: 'center',
            // color: theme.palette.text.secondary,
        },
    }),
);


const App: React.FC = (): ReactElement => {
    const classes = useStyles();
    const queryClient = new QueryClient();

    const [bookmarks, setBookmarks] = useState(GetBookmarks);

    const bookmarksHolder = useMemo(
        () => ({ bookmarks, setBookmarks }),
        [bookmarks],
    );

    const history = useHistory();

    if (history.location.pathname.toLowerCase() == "/blog" || history.location.pathname.toLowerCase() == "/blog/") {
        history.go(0);
        return <></>
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>

                <BookmarksContext.Provider value={bookmarksHolder}>
                    <CssBaseline />

                    <Helmet>
                        <title>CompetitiveCoders.com - Rediscover the joy of programming!</title>
                    </Helmet>


                    <TopHeader />
                    {/* <Container> */}

                    <div className={classes.root}>
                        <Container maxWidth='xl'>
                            <Grid container spacing={4}>
                                <Grid item sm={12} xl={2} lg={3} className={classes.leftColumn}>
                                    <Container>
                                        <LeftColumn />
                                    </Container>
                                </Grid>
                                <Switch>

                                    <Redirect from='/:url*(/+)' to={useLocation().pathname.slice(0, -1)} />
                                    <Route exact path='/'>
                                        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
                                            <Container>
                                                {/*<TwitchPost channelName="ppy" />*/}
                                                {/*<TopFeed />*/}
                                                <FeedLoader sourceId={'main'} feedsource={'main'} />
                                            </Container>
                                        </Grid>
                                        <Grid item sm={12} xl={2} lg={3}>
                                            <Container>
                                                <RightColumn />
                                            </Container>
                                        </Grid>
                                    </Route>


                                    {/*<Route exact path='/calendar'>*/}
                                    {/*    <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>*/}
                                    {/*        <Container>*/}
                                    {/*            <CalendarWrapper />*/}
                                    {/*        </Container>*/}
                                    {/*    </Grid>*/}
                                    {/*    <Grid item sm={12} xl={2} lg={3}>*/}
                                    {/*        <Container>*/}
                                    {/*            <RightColumn />*/}
                                    {/*        </Container>*/}
                                    {/*    </Grid>*/}

                                    {/*</Route>*/}

                                    <Route exact path='/calendar/:year'>
                                        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
                                            <Container>
                                                <CalendarWrapper />
                                            </Container>
                                        </Grid>
                                        <Grid item sm={12} xl={2} lg={3}>
                                            <Container>
                                                <RightColumn />
                                            </Container>
                                        </Grid>

                                    </Route>
                                    <Redirect from='/calendar' to={`/calendar/${new Date().getFullYear()}`} />


                                    <Route exact path='/About'>
                                        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
                                            <About />
                                        </Grid>
                                        <Grid item sm={12} xl={2} lg={3}>
                                            <Container>
                                                <RightColumn />
                                            </Container>
                                        </Grid>
                                    </Route>


                                    {/*//TODO remove route duplication for AoC*/}
                                    {/*<Route exact path="/ProgrammingCompetitions/advent-of-code/:year/:round/:problem/" component={ProgrammingCompetitionsLoader} />*/}
                                    {/*<Route exact path="/ProgrammingCompetitions/advent-of-code/:year/:round/" component={ProgrammingCompetitionsLoader} />*/}
                                    {/*<Route exact path="/ProgrammingCompetitions/advent-of-code/:year/" component={ProgrammingCompetitionsLoader} />*/}
                                    {/*<Route exact path="/ProgrammingCompetitions/advent-of-code/" component={ProgrammingCompetitionsLoader} />*/}


                                    <Route exact
                                           path='/ProgrammingCompetitions/:organization/:competition/:year/:round/:problem/'
                                           component={ProgrammingCompetitionsLoader} />
                                    <Route exact
                                           path='/ProgrammingCompetitions/:organization/:competition/:year/:round/'
                                           component={ProgrammingCompetitionsLoader} />
                                    <Route exact path='/ProgrammingCompetitions/:organization/:competition/:year/'
                                           component={ProgrammingCompetitionsLoader} />
                                    <Route exact path='/ProgrammingCompetitions/:organization/:competition/'
                                           component={ProgrammingCompetitionsLoader} />
                                    <Route exact path='/ProgrammingCompetitions/:organization/'
                                           component={ProgrammingCompetitionsLoader} />
                                    <Route exact path='/ProgrammingCompetitions/'
                                           component={ProgrammingCompetitionsLoader} />


                                    <Route exact path='/AlgorithmsAndDatastructures'>

                                        <LearningPage />


                                    </Route>

                                    <Route exact path='/Resources'>

                                        <Resources />

                                    </Route>


                                    <Route path='*'>
                                        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
                                            <Soft404Widget error={'invalid url'} />
                                        </Grid>
                                        <Grid item sm={12} xl={2} lg={3}>
                                            <Container>
                                                <RightColumn />
                                            </Container>
                                        </Grid>
                                    </Route>

                                </Switch>
                            </Grid>
                        </Container>

                        {/* </Container> */}
                    </div>


                    <Footer />
                </BookmarksContext.Provider>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;