import {
    Chip, Container,
    createStyles,
    Grid, GridSize,
    Link,
    List,
    ListItem,
    ListItemIcon, ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ProblemContentCard } from './ProblemContentCard';
import CodeIcon from '@material-ui/icons/Code';
import GitHubIcon from '@material-ui/icons/GitHub';
import React, { FC, useState } from 'react';
import { ContentItem, GitHubCodeAttachment } from '../../datatypes/feed-interfaces';
import YouTubeIcon from '@material-ui/icons/YouTube';
import StarIcon from '@material-ui/icons/Star';
import { GitHub, HelpOutline } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import AnchorLink from 'react-anchor-link-smooth-scroll';


interface Props {
    contents: ContentItem[] | null,
    md_value: GridSize,
    show_code_column: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {},

            verticallyCentered: {
                display: 'flex',
                marginTop: 'auto',
                marginBottom: 'auto',
            },
            tripleHeader: {
                display: 'flex',
                padding: theme.spacing(),
                paddingBottom: 0,
                marginBottom: theme.spacing(),

            },
            headerMiddle: {
                flexGrow: 1,
                marginLeft: 10,
                marginTop: 'auto',
                marginBottom: 'auto',

            },
            media: {
                height: 120,
                width: '100%',
                // paddingTop: '56.25%', // 16:9
            },
            horizontalitem: {
                marginRight: theme.spacing(1),
            },
        },
    ));


const codeColumns = [
    { field: 'programming_language', headerName: '', width: 90 },
    {
        field: 'programming_language',
        headerName: 'First name',
        width: 150,
        editable: true,
    },
    {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
    },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 110,
        editable: true,
    }];


function GetGithub(item: ContentItem) {

    const pattern = /https:\/\/github.com\/([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gim;
    const url = pattern.exec(item.description);
    return url && url.length > 0 ? url[0] : null;

}


export const ProblemContentDisplay: FC<Props> = (props) => {

    const classes = useStyles();

    const [selectedVideo, setSelectedVideo] = useState('');

    let code_items =props.show_code_column ? props.contents?.map(c => ({
            url: GetGithub(c),
            video_id: c.attachment.type == 'youtube' ? c.attachment.video_id : '',
            programming_language: c.programming_language,
            likes: c.attachment.type == 'youtube' ? c.attachment.likes : 0,
        }),
    ).filter(g => g.url != null)
        .map(g => ({
            url: g.url ? g.url : '',
            video_id: g.video_id,
            programming_language: g.programming_language,
            likes: g.likes,
            author: (g.url!.split('/').slice(3, 5)).join(' /'),
            title: g.url!.split('/').pop(),
        }))
        :
        null;

    if (code_items && code_items.length > 1) {

        code_items = code_items.filter(
            (thing, i, arr) => code_items!.findIndex(t => t.url === thing.url) === i,
        )
            .sort((one, two) => (one.likes > two.likes ? -1 : 1));

    }

    const other_items = props.contents?.filter(c => c.attachment.type != 'githubcode');


    const max_likes = code_items ? Math.max(...code_items.map(o => o.likes)) : 0;

    return <>
        <Grid container xs={12} spacing={0}>

            <Grid container xs={12} md={code_items && code_items.length > 0 ? 8 : 12} spacing={4}>

                {other_items?.map(i =>
                    <Grid key={i.id} item xs={12} md={code_items && code_items.length > 0 ? 4 : props.md_value}>
                        <ProblemContentCard content={i}
                                            is_selected={i.attachment.type == 'youtube' && selectedVideo == i.attachment.video_id} />
                    </Grid>,
                )

                }

                {other_items == null || other_items?.length == 0 ?
                    <div style={{ marginLeft: 20 }}>Content is yet to be found, please come back later or hop on to our <Link href={"https://discord.com/invite/VmjmKQke"} target={"_blank"}>Discord</Link> server to request a faster update!</div> : <></>}

            </Grid>


            {code_items && code_items.length > 0 ?

                <Grid container xs={12} md={4} spacing={4}>
                    <div style={{ marginLeft: 20, paddingLeft: 20, marginTop: 20 }}>

                        <Typography variant={'h5'} style={{ marginLeft: 15 }}> .code() <HelpOutline fontSize={'small'}
                                                                                                    color={'disabled'}
                                                                                                    titleAccess={'Automatically discovered GitHub code for this competition.\nIn the future you will be able to add your own, subscribe to the newsletter to know when.'} /></Typography>


                        <List dense={true} component='nav'>
                            {/*<ListSubheader><div className={classes.verticallyCentered}><GitHubIcon/>&nbsp; Code</div></ListSubheader>*/}
                            {code_items?.map(i =>
                                <ListItem key={'cl' + i.video_id} dense={true} button={true} component={Link}
                                          href={i.url}
                                          target={'blank'}>
                                    <ListItemIcon style={{ minWidth: '36px', marginRight: 20 }}>
                                        <AnchorLink href={'#item-' + i.video_id!}><YouTubeIcon color={'error'}
                                                                                               onClick={() => setSelectedVideo(i.video_id!)} /></AnchorLink>
                                        {/*<StarIcon*/}
                                        {/*    htmlColor={i.likes > 0 ? `rgb(254, 255, 139,${(i.likes / max_likes).toFixed(2)})` : 'rgb(255,255,255,0.1)'} />*/}
                                    </ListItemIcon>

                                    <ListItemText
                                        primary={<>
                                            {i.programming_language ?
                                                <><Chip size='small'
                                                        variant='outlined'

                                                        label={i.programming_language}
                                                        style={{ color: '#FB9583' }}
                                                /><br /></> : null}


                                            {i.author}</>


                                        }
                                        secondary={i.title}
                                        inset={false} />

                                    <ListItemSecondaryAction>
                                        <Link href={i.url} target={'_blank'}>
                                            <GitHubIcon color={'action'} />
                                        </Link>

                                    </ListItemSecondaryAction>
                                </ListItem>,
                            )}

                        </List>

                    </div>
                </Grid>
                :
                null
            }
        </Grid>
    </>
        ;
};