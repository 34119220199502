import React, { FC } from 'react';
import {
    Avatar,
    Checkbox, createStyles,
    Divider,
    Grid,
    LinearProgress,
    List,
    ListItem, ListItemAvatar,
    ListItemIcon, ListItemText,
    ListSubheader, makeStyles,
    Paper, Theme,
    Typography,
} from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import { YoutubeAttachmentPanel } from './components/Feed/FeedItems/YoutubeAttachmentPanel';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        listRoot: {
            width: '100%',
            maxWidth: 360,
        },

        verticallyCentered: {
            // display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        },
        horizontallyCentered: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        containerPadding: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        outerContainer: {
            backgroundColor: '#424242',
            borderRadius: 5,
            marginBottom: theme.spacing(6),
            boxShadow: '2px 2px 5px #1c1c1c',
        },
        videoContainer: {
            display: 'flex',
            overflowX: 'scroll',
            paddingBottom: 10,

            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: '#2b2b2b',
                borderRadius: 8,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: '#6b6b6b',
                // minHeight: 10,
                width: '0.2em',
                border: '3px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: '#2b2b2b',
            },

        },
        checkBox: {
            // color: 'pink',
            '&:hover': {
                backgroundColor: '#505050',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },
        },
        progressColorPrimary: {
            backgroundColor: 'darkgray',
        },
        progressBarColorPrimary: {
            backgroundColor: 'pink',
        },


    }),
);


const data = {
    title: 'Arrays and Dynamic arrays',
    subtitle: 'Some common problems using arrays and vectors/lists',
    videos: ['B2KusJcbVIg', 'jzJlq35dQII', 'On03HWe2tZM', 'umt7t1_X8Rc'],
    theory: [{
        title: 'Introduction to arrays',
        external_url: 'https://www.geeksforgeeks.org/introduction-to-arrays/',
        organization: { slug: 'geeks-for-geeks', title: 'Geeks for geeks' },
    }],
    problems: [{
        title: 'Peak element',
        external_url: 'https://practice.geeksforgeeks.org/problems/peak-element',
        organization: { slug: 'geeks-for-geeks', title: 'Geeks for geeks' },
    },
        {
            title: 'Subarray given sum',
            external_url: 'https://practice.geeksforgeeks.org/problems/subarray-with-given-sum-1587115621/1',
            organization: { slug: 'geeks-for-geeks', title: 'Geeks for geeks' },
        },
        {
            title: 'Remove duplicates from sorted array',
            external_url: 'https://practice.geeksforgeeks.org/problems/subarray-with-given-sum-1587115621/1',
            organization: { slug: 'leetcode', title: 'Leetcode' },
        },
    ],
    exam: [{
        title: 'Code jam 2021 Qualification Round - Reversort',
        external_url: 'Code jam 2021 Qualification Round Reversort ',
        organization: { slug: 'google', title: 'Google' },
    },
    ],

};

function IconUrl(slug: string) {


    if (slug == 'google') {
        return '/logos/google.png';
    } else if (slug == 'leetcode') {
        return '/logos/leetcode-icon.ico';
    } else if (slug == 'meta') {
        return '/logos/meta-icon.png';
    } else if (slug == 'advent-of-code') {
        return '/logos/aoc-icon.png';
    } else if (slug == 'geeks-for-geeks') {
        return '/logos/gfg200.png';
    }

    return '';

}

export const LearningChapterView: FC = () => {

    const classes = useStyles();

    return <Grid item xs={12} className={classes.outerContainer}>
        <LinearProgress variant='determinate' value={50} classes={{
            colorPrimary: classes.progressColorPrimary,
            barColorPrimary: classes.progressBarColorPrimary,
        }} />

        {/*<LinearProgress />*/}
        <Grid container spacing={2} className={classes.containerPadding}>

            <Grid item xs={2} sm={1} lg={1} className={classes.verticallyCentered}>
                <div className={classes.horizontallyCentered}>
                    <CheckBoxOutlineBlank fontSize={'large'} htmlColor={'Pink'} titleAccess={'Introduction'} />
                </div>

            </Grid>
            <Grid item xs={8} sm={9} lg={9} className={classes.verticallyCentered}>
                <Typography variant={'h5'}>


                    <div style={{ marginBottom: 0, marginTop: 5 }}>{data.title}</div>
                    <div style={{ marginTop: -10 }}>
                        <Typography variant={'overline'}>
                            {data.subtitle}
                        </Typography>
                    </div>
                </Typography>
            </Grid>
            <Grid item xs={2} sm={1} lg={1} className={classes.verticallyCentered}>
                <Typography>0/5</Typography>

            </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={3} className={classes.containerPadding}>
            <Grid item xs={12} sm={12} className={classes.verticallyCentered}>
                <ListSubheader component='div' id='nested-list-subheader'>
                    Videos
                </ListSubheader>
                <div className={classes.videoContainer}>
                    {data.videos.map(v =>
                        <Paper elevation={5} style={{ height: 240, width: 320, flex: 'none', marginRight: 15 }}
                               key={v}>

                            <YoutubeAttachmentPanel
                                attachment={{
                                    video_id: v,
                                    type: 'youtube',
                                    views: 0,
                                    likes: 0,
                                    comments: 0,
                                }} />

                            <div style={{ display: 'flex' }}>
                                <div className={classes.verticallyCentered}>
                                    <CheckBoxOutlineBlank className={classes.checkBox} />
                                </div>
                                <div className={classes.verticallyCentered} style={{ marginLeft: 10 }}>
                                    <Typography variant={'caption'}>Length: 2m30s</Typography></div>
                            </div>
                        </Paper>,
                    )
                    }
                </div>
            </Grid>

        </Grid>
        <Divider />
        <Grid container spacing={3} className={classes.containerPadding}>
            <Grid item xs={12} md={6}>

                <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}
                      subheader={<ListSubheader component='div' id='nested-list-subheader'>
                          Theory
                      </ListSubheader>}>

                    {data.theory.map((c) => (
                        <div key={c.title}>

                            <ListItem button dense={true} component='a' target={'_blank'} href={c.external_url}>

                                <ListItemIcon>
                                    <Checkbox
                                        checked={false}
                                        tabIndex={-1}

                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={c.organization.title}
                                        src={IconUrl(c.organization.slug)}
                                    />

                                </ListItemAvatar>

                                <ListItemText primary={c.title}
                                    // secondary={c.external_url}
                                />


                            </ListItem>

                        </div>
                    ))}


                </List>


            </Grid>
            <Grid item xs={12} md={6}>

                <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}
                      subheader={<ListSubheader component='div' id='nested-list-subheader'>
                          Practice problems
                      </ListSubheader>}>

                    {data.problems.map((c) => (
                        <div key={c.title}>

                            <ListItem button dense={true} component='a' target={'_blank'} href={c.external_url}>

                                <ListItemIcon>
                                    <Checkbox
                                        checked={false}
                                        tabIndex={-1}

                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={c.organization.title}
                                        src={IconUrl(c.organization.slug)}
                                    />

                                </ListItemAvatar>

                                <ListItemText primary={c.title}
                                    // secondary={c.external_url}
                                />


                            </ListItem>

                        </div>
                    ))}


                </List>


            </Grid>
        </Grid>
        <Divider />

        <Divider />

        <Grid container spacing={3} className={classes.containerPadding}>
            <Grid item xs={12} className={classes.verticallyCentered}>
                <Typography variant={'h5'}>Exam</Typography>

                <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}>

                    {data.exam.map((c) => (
                        <div key={c.title}>

                            <ListItem button dense={true} component='a' target={'_blank'} href={c.external_url}>

                                <ListItemIcon>
                                    <Checkbox
                                        checked={false}
                                        tabIndex={-1}

                                        inputProps={{ 'aria-label': 'decorative checkbox' }}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={c.organization.title}
                                        src={IconUrl(c.organization.slug)}
                                    />

                                </ListItemAvatar>

                                <ListItemText primary={c.title}
                                    // secondary={c.external_url}
                                />


                            </ListItem>

                        </div>
                    ))}


                </List>


            </Grid>
        </Grid>


    </Grid>;
};