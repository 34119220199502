import { useQuery } from 'react-query';
import { fetchContentItems, fetchExternalContent } from '../../data/repository';
import React, { FC } from 'react';
import { ProblemContentDisplay } from './ProblemContentDisplay';
import { ProblemContentSkeleton } from './ProblemContentSkeleton';
import { Link } from '@material-ui/core';
import { Soft404Widget } from './Soft404Widget';


interface Props {
    organization_slug: string,
    competition_slug: string,
    year: string,
    round_slug: string,
    problem_slug: string,
    limit: number | null,
    programming_language_filter: string[],
    show_code_column?: boolean

}

export const ProblemContentLoader: FC<Props> = ({
                                                    competition_slug,
                                                    limit,
                                                    organization_slug,
                                                    problem_slug,
                                                    programming_language_filter,
                                                    round_slug,
                                                    year,
                                                    show_code_column= true
                                                }) => {

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    // return <ProblemContentSkeleton />;

    const { isLoading, error, status, data } =
        useQuery(['problemcontent', {
                organization: organization_slug,
                competition: competition_slug,
                year: year,
                round: round_slug,
                problem: problem_slug,
            }],
            () => fetchContentItems(organization_slug, competition_slug, year, round_slug, problem_slug),
            { staleTime: 300000 });

    if (isLoading) return (
        <>
            <ProblemContentSkeleton />
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    const filtered_data = data ?
        data.filter(d =>
            programming_language_filter.length == 0
            || (d.programming_language == '' || d.programming_language == null) && programming_language_filter.findIndex(l => l.toLowerCase() == '<unknown>') > -1
            || programming_language_filter.findIndex(l => d.programming_language && l.toLowerCase() == d.programming_language.toLowerCase()) > -1)
        :
        data;

    return filtered_data ?
        <ProblemContentDisplay contents={limit == null ? filtered_data : filtered_data.slice(0, limit)}
                               md_value={limit == 2 ? 6 : 3}
                               show_code_column={show_code_column} /> :
        <div style={{ marginBottom: 20, marginLeft: 40 }}>Content is yet to be found (hop on our <Link href={"https://discord.com/invite/VmjmKQke"} target={"_blank"}>Discord</Link> to request a faster update!)</div>;
};