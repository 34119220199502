import React, { ReactElement } from 'react';
import { DiscordWidget } from '../widgets/DiscordWidget';
import { createStyles, Divider, Link, makeStyles, Theme } from '@material-ui/core';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import { NewsLetterWidget } from './NewsLetterWidget';
import {
    AdventOfCodePrivateLeaderboardLoader,
    AdventOfCodePrivateLeaderboardSkeleton, AdventOfCodePrivateLeaderboardWidget,
} from '../widgets/AdventOfCodePrivateLeaderboard';
import { CountdownWidget } from '../widgets/CountdownWidget';


// interface Props {

// }
const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
        },
    }),
);


export default function RightColumn(): ReactElement {

    const classes = useStyles();


    return (
        <aside style={{ width: '100%' }}>



            {/*<AdventOfCodePrivateLeaderboardWidget limit={10}/>*/}
            {/*<CountdownWidget/>*/}
            <br/>
            {/*<TwitterWidget />*/}
            <DiscordWidget serverId='998511827287810121' />
            <br />


            {/*<div className={classes.sectionDesktop} style={{ width: '100%', marginLeft: 20 }}>*/}

            {/*   */}

            {/*</div>*/}


        </aside>
    );
}