import React, { FC } from 'react';
import {
    createStyles,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles, Theme,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link as RouterLink } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import { Star } from '@material-ui/icons';
import { useQuery } from 'react-query';
import { fetchAocPrivateLeaderboard, fetchContestRounds } from '../data/repository';
import { CompetitionLinksWidgetListSkeleton } from './CompetitionLinksWidgetListSkeleton';
import { CompetitionLinksWidgetList } from './CompetitionLinksWidgetList';
import { Soft404Widget } from '../components/Competitions/Soft404Widget';


export interface AoCLeaderboardRow {
    name: string,
    local_score: number,
    stars: number
}


interface Props{
    rows: AoCLeaderboardRow[],
    limit: number
}

export const AdventOfCodePrivateLeaderboard: FC<Props> = (props: Props) => {
    return <div style={{textAlign: 'left'}}>
        <ol>
            {props.rows.slice(0, props.limit).map((c) => (
                <li key={c.name} style={{marginTop:5}}>

                    <span>[{c.local_score}p]</span>&nbsp;
                    <span style={{color:'#FEFF8B'}}>[{c.stars}*]</span>
                    <br/>
                    <strong>{c.name}</strong>

                </li>

            ))}
</ol>
            <Divider variant={'middle'} />


    </div>;
};


interface LoaderProps {
    limit: number
}
export const AdventOfCodePrivateLeaderboardLoader: FC<LoaderProps> = (props) => {

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    // return <ProblemContentSkeleton />;

    const { isLoading, error, status, data } =
        useQuery(['aoc_private_leaderboard'], () => fetchAocPrivateLeaderboard()
            , { staleTime: 600000 });

    if (isLoading) return (
        <>
            <AdventOfCodePrivateLeaderboardSkeleton />
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <AdventOfCodePrivateLeaderboard rows={data} limit={props.limit} /> :
        <div>&quot unknown &quot data received :(</div>;


};

export const AdventOfCodePrivateLeaderboardSkeleton: FC = () => {
    return <>
        {[1, 2, 3].map((c) => (
            <div key={`rcl${c}`}>
                <ListItem dense={true}>

                    <ListItemIcon>
                        <Skeleton animation='wave' variant='circle' width={20} height={20} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Skeleton animation='wave' height={10} width='100%' style={{ marginBottom: 6 }} />}
                        secondary={<Skeleton animation='wave' height={10} width='50%' style={{ marginBottom: 6 }} />} />

                </ListItem>


            </div>
        ))}
    </>;
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // color: '#444'
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 50,
            // width: '100%',
            // border: '1px dashed rgba(255, 255, 255, 0.5)',
            // borderRadius: 4,
            // padding: 10,
            textAlign: 'center',
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
        },

    }),
);


interface WidgetProps{
    limit: number
}
export const AdventOfCodePrivateLeaderboardWidget: FC<WidgetProps> = (props) =>{
    const classes = useStyles();

    return <aside className={classes.root}>
        <Link component={RouterLink} to={'/ProgrammingCompetitions/advent-of-code/advent-of-code/2022'}>
            <Typography variant={'h6'} style={{color: '#1FBF2D'}}>Advent of Code 2022</Typography>
        </Link>
        <Divider variant={'middle'} style={{marginBottom:10, marginTop:10}}/>
        <AdventOfCodePrivateLeaderboardLoader limit={props.limit}/>
        <Divider variant={'middle'} style={{marginBottom:10}}/>


        <Link  href={'https://adventofcode.com/2022/leaderboard/private'} target={'_blank'}> Join our AoC Leaderboard!</Link>
        <Typography variant={'body2'}>Use code: 1616993-7ba14ff5</Typography>

    </aside>;

}