import React, { FC } from 'react';
import {
    Box,
    createStyles, Grid,
    Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CompetitionRoundProblemLink } from '../../datatypes/competition-round-view-data';
import { StatisticsViewer } from './StatisticsViewer';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // width: '100%',
            // maxWidth: 360,
            //   backgroundColor: theme.palette.background.paper,
        },
        cards: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        framedbox: {
            borderRadius: 5,
            boxShadow: '2px 2px 5px #1c1c1c',
        },

        table: {
            // minWidth: 650,
        },
        problemListItem: {
            // border: '1px solid black',
            padding: 20,
            margin: 10,
            borderRadius: 10,
            boxShadow: '2px 2px 5px #1c1c1c',
            transition: "all .2s ease-in-out",
            "&:hover": {
                // backgroundColor: "#505050",
                transform: "scale(1.05)",
                cursor: "pointer",

            },
        },problemListItemNonClickable: {
            // border: '1px solid black',
            padding: 20,
            margin: 10,
            borderRadius: 10,
            boxShadow: '2px 2px 5px #1c1c1c',
            cursor: 'auto'
        },

    }),
);


interface Props {
    problems: CompetitionRoundProblemLink[];
}

export const ProblemList: FC<Props> = (props: Props) => {
    const classes = useStyles();

    return <Box className={classes.cards}>
        {/*<Typography variant={'h5'}>Problems</Typography>*/}
        {props.problems.length == 0 ? 'Not yet disclosed' : <></>}


        <Grid container xs={12} spacing={2}>
            {props.problems.map(p =>
                <Grid key={`problemlist-${p.slug}`} item xs={12} md={4}>
                    <Link style={{textDecoration: 'none'}}  href={`#problem-${p.slug}`}>
                    <Paper className={p.external_url.includes('advent') ? classes.problemListItemNonClickable : classes.problemListItem}>

                        <Typography variant={'h6'} style={{marginBottom: 5}}>{p.title} [{p.points + 'p.'}]</Typography>

                        {p.test_sets.map(s =>
                            <StatisticsViewer key={s.id} s={s}  />,
                        )}
                        <br />

                        {p.test_sets[0].participants_attempted != p.test_sets[0].fully_accepted ? <>Failed: {`${p.test_sets[0].participants_attempted - p.test_sets[0].fully_accepted - p.test_sets[0].partially_accepted}`} <br/></>
                            : <></>}
                        {p.test_sets[0].partially_accepted != 0 ? <> Some points: {p.test_sets[0].partially_accepted}<br/></> : <></>}
                        Full points: {p.test_sets[0].fully_accepted}<br/>
                        Participants: {p.test_sets[0].participants_total}

                    </Paper>
                </Link>
                </Grid>,
            )}
        </Grid>

        {/*<TableContainer component={Paper} className={classes.framedbox}>*/}
        {/*    <Table className={classes.table} size='small' aria-label='Challenge problems'>*/}
        {/*        <TableBody>*/}


        {/*            {props.problems.map(p =>*/}
        {/*                <TableRow key={p.id}>*/}
        {/*                    <TableCell component='td' scope='row'>*/}
        {/*                        <Link variant={'button'} color={'secondary'}*/}
        {/*                              href={`#problem-${p.slug}`}>{p.title}</Link> {p.points + 'p.'}*/}
        {/*                    </TableCell>*/}
        {/*                    <TableCell>*/}

        {/*                        {p.test_sets.map(s =>*/}
        {/*                            <StatisticsViewer key={s.id} s={s} />*/}
        {/*                        )}*/}

        {/*                    </TableCell>*/}
        {/*                </TableRow>,*/}
        {/*            )*/}
        {/*            }*/}


        {/*        </TableBody>*/}
        {/*    </Table>*/}
        {/*</TableContainer>*/}


    </Box>;
};