import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const CompetitionLinksWidgetListSkeleton: FC = ()=> {
    return  <>
        {[1,2,3].map((c) => (
            <div key={`rcl${c}`}>
                <ListItem dense={true} >

                    <ListItemIcon>
                        <Skeleton animation="wave" variant="circle" width={20} height={20} />
                    </ListItemIcon>
                    <ListItemText primary={ <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />} secondary={ <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />} />

                </ListItem>


            </div>
        ))}
    </>;
}