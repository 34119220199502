import { createStyles, Divider, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

import { ContentItem } from '../../datatypes/feed-interfaces';
import { FeedItemPanel } from './FeedItemPanel';
import { FeedItemNextCompetition } from './FeedItemNextCompetition';
import { FeedItemJustFinishedLoader } from './FeedItemJustFinishedLoader';
import { FeedItemNextCompetitionLoader } from './FeedItemNextCompetitionLoader';
import { CompetitionLinksWidget } from '../../widgets/CompetitionLinksWidget';
import { Container } from '@material-ui/core';
import StartHereLeft from '../../widgets/StartHereLeft';
import { TwitchPost } from './TwitchPost';
import { NewsLetterWidget } from '../NewsLetterWidget';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import TopFeed from './TopFeed';

interface Props {
    feedItems: ContentItem[],
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(1),
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        topDiv: {
            marginTop: -1 * theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
    }),
);


export const Feed: FC<Props> = ({ feedItems }) => {

    const classes = useStyles();

    const quoraData = [{
        link: 'How-should-I-get-started-in-competitive-programming',
        title: 'How should I get started in competitive programming?',
    },
        {
            link: 'How-do-I-get-good-at-math-for-competitive-programming',
            title: 'How do I get good at math for competitive programming',
        },
        {
            link: 'What-is-competitive-programming-2',
            title: 'What is competitive programming?',
        },

    ];


    return (
        <main className={classes.root}>
            <Helmet>
                <title>CompetitiveCoders - Rediscover the joy of programming from when you started out!</title>
                <meta
                    name="description"
                    content="A community for competitive coders and competitive programming! Stay up to date and meet other competitive programmers and their solutions!"
                />

            </Helmet>
            <div className={`${classes.sectionMobile} ${classes.topDiv}`}>
                <NewsLetterWidget />
            </div>

            {new Date() < new Date('2022-11-08 23:59:00')
                ?
                <div>
                <TwitchPost channelName={'consid_ab'} />
                    <Divider variant={'middle'} />
                    <br />
                </div>
                :
                 null
            }


            <FeedItemNextCompetitionLoader />

            <FeedItemJustFinishedLoader />

            {/*<TopFeed/>*/}


            <br />

            <Grid container xs={12} spacing={1} className={classes.sectionMobile}>
                <Grid item sm={6} md={6}>
                    <CompetitionLinksWidget source={'recent'} elements={7} />
                </Grid>
                <Grid item sm={6} md={6}>
                    <CompetitionLinksWidget source={'upcoming'} elements={5} />
                </Grid>
            </Grid>
            <br />

            <Typography variant={'h5'} component={'h1'}>
                Competitive programming
            </Typography>
            <br />
            <Grid container xs={12} spacing={0}>
                {feedItems.slice(0, 15).map(c => (

                    <FeedItemPanel key={c.id} feedItem={c} />

                ))}
            </Grid>

            {/*<PostBase childComp={<QuoraPost data={quoraData} />} logoNode={<QuoraLogoNode/>}/>*/}

        </main>
    );
};
