import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header_image: {
            maxWidth: '50%',
            maxHeight: '50%',
            borderRadius: 5,
            boxShadow: '2px 2px 5px #1c1c1c',
            border: '1px solid #444',
        },

    }),
);
export const Soft404Widget = (props: { error: string | Error }) => {
    const classes = useStyles();
    return <div style={{ textAlign: 'center' }}>
        <Helmet>
            <title>404 Not found :(</title>
        </Helmet>
        <br /><br />
        <img src={'/404.jpg'} alt={'404 Not found'} className={classes.header_image} />
        <br />
        This is not the page you are looking for :(
        <br /><br />
        <Typography variant={'h4'} component={'h1'}>Go directly to:</Typography>
        <br />
        <Button variant='contained' color='primary' component={RouterLink} to={'/ProgrammingCompetitions'} >Competitions</Button>&nbsp;&nbsp;or&nbsp;&nbsp;
        <Button variant='contained' color='secondary' component={RouterLink} to={`/calendar/${new Date().getFullYear()}`}>Calendar</Button>&nbsp;&nbsp;or&nbsp;&nbsp;
        <Button variant='contained' component={RouterLink} to={'/'}>Main feed </Button>
    </div>;
};