import React, { FC } from 'react';
import { CalendarSkeleton } from './CalendarSkeleton';
import { useQuery } from 'react-query';
import { fetchCalendarYear} from './data/repository';
import { Calendar } from './components/Calendar/Calendar';
import { Soft404Widget } from './components/Competitions/Soft404Widget';

interface Props {
    year: number,
}

export const CalendarLoader :FC<Props>= (props: Props) => {



    if ( props.year < 2007 || props.year > new Date().getFullYear() ){
        return <Soft404Widget error={"invalid year"} />
    }

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    const { isLoading, error, status, data } =
        useQuery(['calendar', {
                year: props.year
            }], () => fetchCalendarYear(props.year),
            { staleTime: 7200000 });

    if (isLoading) return (
        <>
            <CalendarSkeleton />
        </>
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <Calendar rounds={data} year={props.year}/> : <div></div>;
};