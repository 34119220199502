import React, { FC } from 'react';
import { CompetitionOrganizationLink } from '../../datatypes/competition-round-view-data';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import {
    Avatar,
    createStyles,
    Divider,
    Grid,
    Link,
    List,
    ListItem, ListItemAvatar,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import { CompetitionHeader } from './CompetitionHeader';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import { ProblemContentLoader } from './ProblemContentLoader';
import { ListSubheader } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2,

        },
        problemListItem: {
            // border: '1px solid black',
            // maxHeight: 100,
            padding: 20,
            margin: 10,
            borderRadius: 10,
            // maxHeight: 120,
            boxShadow: '2px 2px 5px #1c1c1c',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                backgroundColor: '#414557',
                transform: 'scale(1.01)',
                cursor: 'pointer',

            },
        },
    }),
);

export const CompetitionOrganizationList: FC = () => {

    const classes = useStyles();


    const major_organizations: CompetitionOrganizationLink[] = [
        {
            title: 'Advent of Code',
            slug: 'advent-of-code',
            external_url: 'https://adventofcode.com/',
            id: 'tba',
            image: '/logos/aoc-icon.png',
        },
        {
            title: 'Google',
            slug: 'google',
            external_url: 'https://codingcompetitions.withgoogle.com/',
            id: 'tba',
            image: '/logos/google.png',
        },
        {
            title: 'Meta (Facebook)',
            external_url: 'https://www.facebook.com/codingcompetitions',
            slug: 'meta',
            id: 'tba',
            image: '/logos/metalogo.jpg',
        },
    ];

    const weekly_organizations: CompetitionOrganizationLink[] = [
        {
            title: 'Leetcode',
            slug: 'leetcode',
            external_url: 'https://www.leetcode.com/',
            id: 'tba',
            image: '/logos/leetcode.jpg',
        },
    ];

    const other_organizations: CompetitionOrganizationLink[] = [
        {
            title: 'Consid',
            slug: 'consid',
            external_url: 'https://www.consid.se/',
            id: 'tba',
            image: '/logos/considition.png',
        },
    ];


    function IconUrl(slug: string) {


        if (slug == 'google') {
            return '/logos/google.png';
        } else if (slug == 'leetcode') {
            return '/logos/leetcode-icon.ico';
        } else if (slug == 'meta') {
            return '/logos/meta-icon.png';
        } else if (slug == 'advent-of-code') {
            return '/logos/aoc-icon.png';
        } else if (slug == 'consid') {
            return '/logos/considition.png';
        }

        return '';

    }

    const baseUrl = `/ProgrammingCompetitions/`;

    return <>
        <Helmet>
            <title>{`Solutions to major programming competitions!`}</title>
            <meta
                name="description"
                content={`See other's solutions and walkthroughs on CompetitiveCoders.com for the big programming competitions!`}
            />
        </Helmet>
        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} component={'main'}>

            <Typography variant='h3' component='h2'>
                Programming competitions

            </Typography>
            <br />

            {/*<Grid container xs={12} spacing={1}>*/}
            {/*    {organizations.map((c) => (*/}
            {/*        <Grid key={`org-${c.slug}`} item xs={4} md={3}>*/}
            {/*            <Link component={RouterLink} to={baseUrl + c.slug} color={'inherit'}>*/}
            {/*                <div  className={classes.problemListItem}>*/}
            {/*                    <img src={c.image} style={{height: 'auto', width: '100%'}} />*/}
            {/*                    <Typography style={{ textAlign: 'center' }}>{c.title}</Typography>*/}

            {/*                </div>*/}
            {/*            </Link>*/}
            {/*        </Grid>*/}
            {/*    ))}*/}

            {/*</Grid>*/}
            <Grid container spacing={4}>

                <Grid item sm={12} md={6}>
                    <List component='nav' aria-label='Rounds' dense={true}
                          subheader={<ListSubheader component='div' id='major-org-subheader' disableSticky={true}>Major
                              competitions</ListSubheader>}
                    >

                        {major_organizations.map((o) => (
                            <div key={o.slug}>
                                <ListItem button dense={true} component={RouterLink}
                                          to={baseUrl + o.slug + (o.slug == 'advent-of-code' ? '/advent-of-code' : '')}
                                          className={classes.problemListItem}>
                                    <ListItemIcon>
                                        <img src={IconUrl(o.slug)} alt={o.title} height={30} width={30} />
                                        {/*<EmojiEventsIcon />*/}
                                    </ListItemIcon>
                                    <ListItemText primary={o.title} />

                                </ListItem>


                            </div>
                        ))}

                    </List>
                </Grid>
                <Grid item sm={12} md={6}>
                    <List component='nav' aria-label='Rounds' dense={true}
                          subheader={<ListSubheader component='div' id='weekly-org-subheader'
                                                    disableSticky={true}  >Weekly</ListSubheader>}
                    >

                        {weekly_organizations.map((o) => (
                            <div key={o.slug}>
                                <ListItem button dense={true} component={RouterLink}
                                          to={baseUrl + o.slug + (o.slug == 'advent-of-code' ? '/advent-of-code' : '')}
                                          className={classes.problemListItem}>
                                    <ListItemIcon>
                                        <img src={IconUrl(o.slug)} alt={o.title} height={30} width={30} />
                                        {/*<EmojiEventsIcon />*/}
                                    </ListItemIcon>
                                    <ListItemText primary={o.title} />

                                </ListItem>


                            </div>
                        ))}

                    </List>


                    <List component='nav' aria-label='Rounds' dense={true}
                          subheader={<ListSubheader component='div' id='occational-org-subheader' disableSticky={true}>Misc
                              fun
                              stuff</ListSubheader>}
                    >

                        {other_organizations.map((o) => (
                            <div key={o.slug}>
                                <ListItem button dense={true} component={RouterLink}
                                          to={baseUrl + o.slug + (o.slug == 'advent-of-code' ? '/advent-of-code' : '')}
                                          className={classes.problemListItem}>
                                    {IconUrl(o.slug) != '' ?
                                        <ListItemIcon>
                                            <img src={IconUrl(o.slug)} alt={o.title} height={30} width={30} />
                                            {/*<EmojiEventsIcon />*/}
                                        </ListItemIcon>
                                        :
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={o.title}

                                            >{o.title[0]}</Avatar>
                                        </ListItemAvatar>
                                    }


                                    <ListItemText primary={o.title} />

                                </ListItem>


                            </div>
                        ))}

                    </List>

                </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Typography variant='h4' component='h3'>
                {'Needz moar?'}
            </Typography>
            <Link href={'https://clist.by'} target={'_blank'}>Visit CList.by for an extensive list of current coding
                competitions....</Link>

            <br />
            <br />
            <Divider />
            <br />

            <ProblemContentLoader organization_slug={''}
                                  competition_slug={''}
                                  year={''}
                                  round_slug={''}
                                  problem_slug={''}
                                  limit={12}
                                  programming_language_filter={[]}
            />

        </Grid>
    </>;
};