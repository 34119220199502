import { Breadcrumbs, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { FC } from 'react';

export const CompetitionBreadcrumbsSkeleton : FC = () => <>
    <Breadcrumbs aria-label='breadcrumb'>

        <Skeleton animation='wave' height={10} width={80} style={{ marginBottom: 6 }} />
        <Skeleton animation='wave' height={10} width={80} style={{ marginBottom: 6 }} />
        <Skeleton animation='wave' height={10} width={80} style={{ marginBottom: 6 }} />
        <Skeleton animation='wave' height={10} width={80} style={{ marginBottom: 6 }} />

    </Breadcrumbs>
    <Divider style={{ marginBottom: 15 }} />
</>;