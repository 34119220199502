const getItem = (key: string) => {

    const item = localStorage.getItem(key);

    if (item === null) return undefined;

    if (item === 'null') return null;
    if (item === 'undefined') return undefined;

    try {
        return JSON.parse(item);
    } catch {
    }

    return item;
};

const setItem = (key: string, value: any) => {
    if (value === undefined) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

const localstorage_bookmarks_key = 'bookmark-links';

export interface BookmarkItem {
    title: string,
    url: string
}

export const GetBookmarks = () => {
    return getItem(localstorage_bookmarks_key);
};


export const AddBookmark = (bookmark: BookmarkItem) => {

    let current_bookmarks: BookmarkItem[] = getItem(localstorage_bookmarks_key);

    if (current_bookmarks) {
        // already done
        if ( current_bookmarks.find(f => f.url.toLowerCase() === bookmark.url.toLowerCase()))
            return;
    } else {
        current_bookmarks = [];
    }

    current_bookmarks.push(bookmark);
    setItem(localstorage_bookmarks_key, current_bookmarks);

};

export const RemoveBookmark = (bookmark: BookmarkItem) => {
    const current_bookmark: BookmarkItem[] = getItem(localstorage_bookmarks_key);
    if (current_bookmark) {
        const currentItemIndex = current_bookmark.findIndex(f => f.url.toLowerCase() === bookmark.url.toLowerCase());
        if (currentItemIndex === -1) {
            return;
        }

        if (currentItemIndex > -1) {
            current_bookmark.splice(currentItemIndex, 1);
        }

        setItem(localstorage_bookmarks_key, current_bookmark);
    }
};

export const IsBookmarked: (url: string) => boolean = (url: string) => {
    const current_bookmark: BookmarkItem[] = getItem(localstorage_bookmarks_key);

    if (current_bookmark) {
        return current_bookmark.findIndex(f => f.url.toLowerCase() === url.toLowerCase()) > -1;
    }

    return false;
};