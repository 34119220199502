import { CompetitionRoundLink, CompetitionRoundViewData } from '../datatypes/competition-round-view-data';
import {
    Box,
    createStyles,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, ListSubheader,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { FC, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';

interface Props {
    competitionRounds: CompetitionRoundViewData[],
    source: string,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
        },
        expandButton: {
            float: 'right',
            height: 30,
            width: 30,
            marginTop: 5,
            paddingLeft: 3,
            borderRadius: 4,
            boxShadow: '2px 2px 5px #1c1c1c',
            transition: "all .2s ease-in-out",
            "&:hover": {
                backgroundColor: "#505050",
                transform: "scale(1.1)",
                cursor: "pointer",

            },

            // marginRight: theme.spacing(4)
        }
    }),
);

function IconUrl(slug: string) {


    if (slug == 'google') {
        return '/logos/google.png';
    } else if (slug == 'leetcode') {
        return '/logos/leetcode-icon.ico';
    } else if (slug == 'meta') {
        return '/logos/meta-icon.png';
    } else if (slug == 'advent-of-code') {
        return '/logos/aoc-icon.png';
    } else if (slug == 'consid') {
        return '/logos/considition.png';
    }

    return '';

}

export const CompetitionLinksWidgetList: FC<Props> = (props) => {

    const classes = useStyles();

    const [showAll, setShowAll] = useState(false);


    function toggleShowAll() {
        return setShowAll(!showAll);
    }

    function filterRounds(inputRounds: CompetitionRoundViewData[]) {
        //
        // if (showAll) {
        //     return inputRounds;
        // }

        const filtered_rounds : CompetitionRoundViewData[] = [];

        const added = new Set<string>();

        inputRounds.forEach(value => {
            if (!added.has(value.competition.slug)) {
                added.add(value.competition.slug);
                filtered_rounds.push(value);
            }
        });

        return filtered_rounds;

    }

    let shownRounds: CompetitionRoundViewData[] = [];

    if ( showAll ){
        shownRounds = props.competitionRounds;
    }else{
        shownRounds = filterRounds(props.competitionRounds);
    }

    return <Box>

            <List component='nav' className={classes.root} aria-label='competitions' dense={true}
            subheader={<ListSubheader component="div" id="nested-list-subheader">
                {props.source === 'recent' ? 'Recent' : 'Upcoming'} contests
                <div className={classes.expandButton} onClick={(event: React.MouseEvent<HTMLElement>) => {
                    toggleShowAll();
                }}>
                    <FilterListIcon color={showAll ? 'inherit' : 'primary'} style={{marginLeft: 'auto', marginRight: 'auto'}} />
                </div>
            </ListSubheader>}>

                {shownRounds.map((c) => (
                    <div key={c.slug}>
                        <ListItem button dense={true} component={RouterLink}
                                  to={`/ProgrammingCompetitions/${c.organization.slug}/${c.competition.slug}/${c.year}/${c.slug}`}>

                            <ListItemIcon>
                                <img src={IconUrl(c.organization.slug)} alt={c.organization.title} height={30} width={30} />
                                {/*<EmojiEventsIcon />*/}
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant={'body2'}
                                                          color={c.organization.slug == 'google' || c.organization.slug == 'meta' || c.organization.slug == 'advent-of-code' ? 'inherit' : 'textSecondary' }>
                                                        {`${c.competition.title} ${c.title}`}
                                                    </Typography>}
                                          secondary={format(c.start_time, "yyyy-MM-dd HH:mm")} />

                        </ListItem>

                    </div>
                ))}

                <Divider variant={'middle'} />

                <ListItem button component={RouterLink}
                          to={`/calendar/${new Date().getFullYear()}`}>
                    {/*<ListItemIcon><EventIcon/></ListItemIcon>*/}
                    <ListItemText primary={<div style={{ display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',}}><EventIcon fontSize={'small'} style={{ marginRight:6}}/> <Typography variant={'overline'} style={{marginTop: 3}}>Go to calendar</Typography></div>}  inset={true} />
                </ListItem>
                <Divider variant={'middle'}/>
            </List>

        </Box>;

};