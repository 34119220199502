import { ContentItem, ContentCategory } from '../datatypes/feed-interfaces';
import { CompetitionRoundLink, CompetitionRoundViewData } from '../datatypes/competition-round-view-data';
import { CompetitionYearViewData } from '../components/Competitions/CompetitionYearView';
import { CompetitionViewData } from '../components/Competitions/CompetitionView';
import { CompetitionOrganizationViewData } from '../components/Competitions/CompetitionOrganizationView';
import { AoCLeaderboardRow } from '../widgets/AdventOfCodePrivateLeaderboard';



async function delay(ms: number) {
    return new Promise(resolve =>
        setTimeout(resolve, ms),
    );
}


export async function fetchCalendarYear(year: number) {

    const response = await fetch(`/api/Calendar?year=${year}`);

    const data = await response.json();

    const result: CompetitionRoundViewData[] = data;

    result.forEach(el => el.start_time = new Date(el.start_time));
    result.forEach(el => el.end_time = new Date(el.end_time));

    return result;

}export async function fetchCompetitionYears(competition_slug: string, organization_slug: string) {

    const response = await fetch(`/api/Competition?competition=${competition_slug}&organization=${organization_slug}`);

    const data = await response.json();

    const result: CompetitionViewData = data;



    return result;

}

export async function fetchOrganization(organization_slug: string) {

    const response = await fetch(`/api/Organization?organization=${organization_slug}`);

    const data = await response.json();

    const result: CompetitionOrganizationViewData = data;


    return result;

}


export async function fetchContestRoundsByYear(competition_slug: string, year: number, organization: string) {


    // await delay(1000);


    const response = await fetch(`/api/CompetitionYear?competition=${competition_slug}&year=${year}&organization=${organization}`);

    const data = await response.json();

    const result: CompetitionYearViewData = data;


    result.rounds.forEach(el => {
        el.start_time = new Date(el.start_time);
    });

    //TODO
    //      const transformApiFeedItem = (apiFeedItem: ApiFeedItem): FeedItem => ({
    // …apiFeedItem,
    //          timestamp: new Date(apiFeedItem.timestamp),
    //  )};
    //

    return result;


}


export async function fetchContestRoundbySlug(competition_round_slug: string, competition_slug: string, organization_slug: string, year: string) {


    const response = await fetch(`/api/CompetitionRound?organization=${organization_slug}&competition=${competition_slug}&round=${competition_round_slug}&year=${year}`);

    const data = await response.json();

    const result: CompetitionRoundViewData = data;

    result.start_time = new Date(result.start_time);
    result.end_time = new Date(result.end_time);
    result.year = result.start_time.getFullYear();

    //TODO
    //      const transformApiFeedItem = (apiFeedItem: ApiFeedItem): FeedItem => ({
    // …apiFeedItem,
    //          timestamp: new Date(apiFeedItem.timestamp),
    //  )};
    //

    return result;

    // const rounds = repository_competitionrounds[`competitionround_${slug}`][0];


}


export async function fetchAocPrivateLeaderboard() {


    const response = await fetch(`/api/AoCPrivateLeaderboard`);

    const data = await response.json();

    const result: AoCLeaderboardRow[] = data;


    return result;

}


export async function fetchContestRounds(source: string, elements: number) {


    const response = await fetch(`/api/CompetitionRoundsWidget?slug=${source}&limit=${elements}`);

    const data = await response.json();

    const result: CompetitionRoundViewData[] = data;


    result.forEach(el => el.start_time = new Date(el.start_time));
    result.forEach(el => el.end_time = new Date(el.end_time));


    return result;


    //TODO
    //      const transformApiFeedItem = (apiFeedItem: ApiFeedItem): FeedItem => ({
    // …apiFeedItem,
    //          timestamp: new Date(apiFeedItem.timestamp),
    //  )};
    //

    // await delay(5000);
    // const rounds = repository_competitionrounds[`competitionrounds_${source}`].slice(0,elements);
    //
    // return rounds;

}


export async function fetchContentItems(organization: string, competition: string, year: string, round: string, problem: string) {

    const url = `/api/ContentItems?organization=${organization}&competition=${competition}&year=${year}&round=${round}&problem=${problem}`;
    const response = await fetch(url);

    if (response.status != 200) {
        return null;
    }

    const data = await response.json();

    const contentItems: ContentItem[] = data;

    contentItems.forEach(el => el.timestamp = new Date(el.timestamp));

    return contentItems;


    //TODO
    //      const transformApiFeedItem = (apiFeedItem: ApiFeedItem): FeedItem => ({
    // …apiFeedItem,
    //          timestamp: new Date(apiFeedItem.timestamp),
    //  )};
    //
    //      const feedItems = apiFeedItems.map(transformApiFeedItem);

}

export async function fetchExternalContent(feedsource: string) {


    // if (false && feedsource === 'main') {
    const response = await fetch(`/api/Feed?source=${feedsource}`);

    const data = await response.json();


    const feedItems: ContentItem[] = data;

    feedItems.forEach(el => el.timestamp = new Date(el.timestamp));

    //TODO
    //      const transformApiFeedItem = (apiFeedItem: ApiFeedItem): FeedItem => ({
    // …apiFeedItem,
    //          timestamp: new Date(apiFeedItem.timestamp),
    //  )};
    //
    //      const feedItems = apiFeedItems.map(transformApiFeedItem);
    return feedItems;

    // } else {
    //
    //
    //     // const [, { feedsource, sourceId }] = params.queryKey;
    //
    //     await delay(2000);
    //
    //     // return repository_externalcontent[sourceId];
    //     return repository_externalcontent["fbhc2021qr"];
    //
    //
    // }
}

