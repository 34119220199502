import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { fetchCompetitionYears } from '../../data/repository';
import { CompetitionViewSkeleton } from './CompetitionViewSkeleton';
import { CompetitionView } from './CompetitionView';
import { Soft404Widget } from './Soft404Widget';

interface Props {
    competition_slug: string,
    organization_slug: string,
}

export const CompetitionViewLoader: FC<Props> = (props) => {

    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }


    const { isLoading, error, status, data } =
        useQuery(['competitionyears', {
            competition_slug: props.competition_slug,
            organization_slug: props.organization_slug,
        }], () => fetchCompetitionYears(props.competition_slug, props.organization_slug),
            { staleTime: 7200000 });

    if (isLoading) return (
        <CompetitionViewSkeleton />
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <CompetitionView competition_data={data} /> : <div>&quot unknown &quot data received :(</div>;
};