import React, { FC, ReactElement } from 'react';
import { CompetitionLinksWidgetListLoader } from './CompetitionLinksWidgetListLoader';


interface Props {
    source: string,
    elements: number
}

export const CompetitionLinksWidget: FC<Props> = (props): ReactElement => {

    return (


                <CompetitionLinksWidgetListLoader source={props.source} elements={props.elements} />


    );
};


