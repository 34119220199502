import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link as RouterLink } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import Typography from '@material-ui/core/Typography';
import { BookmarkItem, GetBookmarks } from '../utils/storage-repository';
import StarIcon from '@material-ui/icons/Star';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { BookmarksContext } from '../utils/bookmark-context';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const useStyles = makeStyles({
    root: {
        // minWidth: 275,
        // marginTop: 20,
        // marginBottom: 20,
        // padding: 5,
    },

});


export const BookmarksWidget: FC = () => {
    const classes = useStyles();

    // const bookmarks : BookmarkItem[] = GetBookmarks();
    const { bookmarks } = useContext(BookmarksContext);

    return <Box>

        <List component='nav' className={classes.root} aria-label='competitions' dense={true}
              subheader={<ListSubheader component='div'>
                  Bookmarks
              </ListSubheader>}>

            {!bookmarks || bookmarks.length == 0 ?
                <ListItem dense={true}>
                    <ListItemText secondary={'Nothing bookmarked'} inset={true} />
                </ListItem>

                :

                bookmarks.map((c) => (

                    <ListItem key={c.url} button dense={true} component={RouterLink}
                              to={c.url}>

                        <ListItemIcon>
                            {/*<StarIcon/>*/}
                            {/*<EmojiEventsIcon />*/}
                            <BookmarkIcon />
                        </ListItemIcon>
                        <ListItemText primary={c.title} />
                    </ListItem>
                ))
            }

        </List>

    </Box>;
};