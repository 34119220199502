import React, { FC } from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { CompetitionBreadcrumbsSkeleton } from './CompetitionBreadcrumbsSkeleton';
import { CompetitionHeaderSkeleton } from './CompetitionHeaderSkeleton';
import Skeleton from '@material-ui/lab/Skeleton';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2

        }
    }),
);

export const CompetitionViewSkeleton: FC = () => {

    const classes = useStyles();

    return <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
        <CompetitionBreadcrumbsSkeleton />
        <CompetitionHeaderSkeleton />

        {[1, 2, 3].map(i =>
            <Skeleton key={`cys${i}`} animation='wave' height={20} width={20} style={{ marginBottom: 6 }} />,
        )
        }

    </Grid>;
};