import { YoutubeAttachment } from '../../datatypes/feed-interfaces';
import { Container } from '@material-ui/core';
import { YoutubeAttachmentPanel } from '../Feed/FeedItems/YoutubeAttachmentPanel';
import React, { FC } from 'react';


interface Props {
    youtube_video_id: string
}

export const CompetitionWelcomeMedia : FC<Props> = (props: Props) => {
    return <Container maxWidth='sm'>
        <YoutubeAttachmentPanel attachment={ {type: 'youtube',
            video_id: props.youtube_video_id,  views:0, likes:0, comments:0 } } />
    </Container>;
}