import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { updateVideoLike } from '../../data/adminapi';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adminButton: {
            float: 'right',

        },
        thumb: {
            marginLeft: 20,
            '&:hover': {
                // backgroundColor: '#505050',
                transform: 'scale(1.05)',
                cursor: 'pointer',

            },
        },
        hidden: {
            display: 'none',
        },
    }),
);

interface Props {
    video_id: string
}


export const LikeMenu: FC<Props>= (props) => {

    const [is_liked, setIsLiked] = React.useState(false);
    const [is_disliked, setIsDisliked] = React.useState(false);



    const handleLike = async (add_likes: number) => {
        await updateVideoLike(props.video_id, add_likes);
        if (add_likes > 0){
            setIsLiked(true);
        }else{
            setIsDisliked(true);
        }
    }


    const classes = useStyles();

    return <span className={process.env.NODE_ENV === 'development' ? classes.adminButton : classes.hidden}>
    <ThumbUp className={classes.thumb} color={is_liked ? 'primary' : 'inherit'} onClick={() => handleLike(1)}/>
    <ThumbDown className={classes.thumb} color={is_disliked ? 'primary' : 'inherit'} onClick={() => handleLike(-1)}/>

    </span>;
}
