import React, { FC } from 'react';
import { CompetitionRoundViewSkeleton } from './CompetitionRoundViewSkeleton';
import { useQuery } from 'react-query';
import { fetchContestRoundbySlug } from '../../data/repository';
import CompetitionRoundView from './CompetitionRoundView';
import { Soft404Widget } from './Soft404Widget';

interface Props {
    competition_round_slug: string,
    competition_slug: string,
    organization_slug: string,
    year: string,
}

export const CompetitionRoundViewLoader: FC<Props> = (props) => {


    function isError(error: unknown): error is Error {
        return error instanceof Error;
    }

    const { isLoading, error, status, data } =
        useQuery(['contestround', {
            competition_round: props.competition_round_slug,
            competition: props.competition_slug,
            organization: props.organization_slug,
            year: props.year,
        }], () => fetchContestRoundbySlug(props.competition_round_slug, props.competition_slug, props.organization_slug, props.year),
            { staleTime: 300000 });

    if (isLoading) return (
        <CompetitionRoundViewSkeleton />
    );

    if (status === 'error') {
        if (isError(error)) {

            return (<Soft404Widget error={error} />);
        } else {
            return (<div>An unknown error has occurred :( </div>);
        }
    }


    return data ? <CompetitionRoundView competition_round={data} /> : <div>&quot unknown &quot data received :(</div>;


};