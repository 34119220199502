import React from 'react'

interface Props {
    serverId: string
}

export const DiscordWidget = (props: Props) => {
    const widgetUrl = `https://discord.com/widget?id=${props.serverId}&theme=dark`;
    
    return (
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              {/* <div style="border: 1px solid gray; border-radius: 10px; overflow: hidden; position: relative; height: 300px;"> */}
              {/*<iframe src={widgetUrl} scrolling="no" height="300px" width="100%"*/}
              {/*  frameBorder={0}></iframe>*/}

            <iframe src={widgetUrl} height="400"
                    allowTransparency={true} frameBorder="0"
                    sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >

            </iframe>


            {/* <iframe
                src="https://discordapp.com/widget?id=628467134200020992&theme=dark"
                height={500}
                frameBorder={0}
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe> */}
        </div>
    )
}


