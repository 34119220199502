import React, { FC } from 'react';
import {
    Checkbox,
    Container,
    createStyles,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { School } from '@material-ui/icons';
import { LearningChapterView } from './LearningChapterView';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        listRoot: {
            width: '100%',
            maxWidth: 360,
        },
        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2,

        },
        verticallyCentered: {
            // display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        },
        horizontallyCentered: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        containerPadding: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        outerContainer: {
            backgroundColor: '#424242',
            borderRadius: 5,
            marginBottom: theme.spacing(6),
            boxShadow: '2px 2px 5px #1c1c1c',
        },
        videoContainer: {
            display: 'flex',
            overflowX: 'scroll',
            paddingBottom: 10,
            // '&::-webkit-scrollbar': {
            //     width: '0.3em',
            //     // height: '0.2em',
            // },
            // '&::-webkit-scrollbar-track': {
            //     // backgroundColor: '#101010',
            //     // borderRadius: 20,
            //     '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            // },
            // '&::-webkit-scrollbar-thumb': {
            //     backgroundColor: '#d6dee1',
            //     borderRadius: 20,
            //     border: '6px solid transparent',
            //     backgroundClip: 'content-box',
            // }
            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                backgroundColor: '#2b2b2b',
                borderRadius: 8,
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                borderRadius: 8,
                backgroundColor: '#6b6b6b',
                // minHeight: 10,
                width: '0.2em',
                border: '3px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                backgroundColor: '#2b2b2b',
            },

        },
        checkBox: {
            // color: 'pink',
            '&:hover': {
                backgroundColor: '#505050',
                transform: 'scale(1.1)',
                cursor: 'pointer',

            },
        },
        progressColorPrimary: {
            backgroundColor: 'darkgray',
        },
        progressBarColorPrimary: {
            backgroundColor: 'pink',
        },


    }),
);




export const LearningPage: FC = () => {

    const classes = useStyles();

    return <>
        <Helmet>
            <title>{`CompetitiveCoders - Learn algorithms and datastructures through Competitive Programming`}</title>
        </Helmet>
        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn}>
            <div style={{ display: 'flex' }}>
                <div style={{ paddingTop: 10, marginRight: 10 }}>
                    <School fontSize={'large'} />
                </div>
                <div>
                    <Typography variant='h3' component='h2'>
                        Learning Center
                    </Typography>
                    <Typography variant='h4' component='h3'>
                        Getting started
                    </Typography>
                </div>
            </div>
            <br />
            <br />

            <LearningChapterView />


        </Grid>


        {/*Right hand side*/}
        <Grid item sm={12} xl={2} lg={3}>
            <Container>
                <Paper>
                    <LinearProgress variant='determinate' value={50} classes={{
                        colorPrimary: classes.progressColorPrimary,
                        barColorPrimary: classes.progressBarColorPrimary,
                    }} />
                    <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}
                          subheader={<ListSubheader component='div' id='nested-list-subheader'>
                              Getting started
                          </ListSubheader>}>

                        {['Arrays and Dynamic arrays', 'Recursion', 'Complexity', 'Memoization', 'Linked lists'].map((c) => (
                            <div key={c}>

                                <ListItem button dense={true}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={false}
                                            tabIndex={-1}

                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={c}
                                        // secondary={c.external_url}
                                    />


                                </ListItem>

                            </div>
                        ))}
                    </List>
                </Paper>

                <Paper style={{ marginTop: 20 }}>
                    <LinearProgress variant='determinate' value={50} classes={{
                        colorPrimary: classes.progressColorPrimary,
                        barColorPrimary: classes.progressBarColorPrimary,
                    }} />
                    <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}
                          subheader={<ListSubheader component='div' id='nested-list-subheader'>
                              Getting the groove
                          </ListSubheader>}>

                        {['Stacks/Queues', 'Trees', 'Backtracking', 'Binary search trees', 'DFS/BFS', 'Heaps'].map((c) => (
                            <div key={c}>

                                <ListItem button dense={true}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={false}
                                            tabIndex={-1}

                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={c}
                                        // secondary={c.external_url}
                                    />


                                </ListItem>

                            </div>
                        ))}
                    </List>
                </Paper>

                <Paper style={{ marginTop: 20 }}>
                    <LinearProgress variant='determinate' value={50} classes={{
                        colorPrimary: classes.progressColorPrimary,
                        barColorPrimary: classes.progressBarColorPrimary,
                    }} />
                    <List component='nav' className={classes.listRoot} aria-label='theory' dense={true}
                          subheader={<ListSubheader component='div' id='nested-list-subheader'>
                              NinjaRockstarSeñorDev
                          </ListSubheader>}>

                        {['Greedy', 'Segment trees', 'Graphs', 'Dynamic programming', 'Binary search'].map((c) => (
                            <div key={c}>

                                <ListItem button dense={true}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={false}
                                            tabIndex={-1}

                                            inputProps={{ 'aria-label': 'decorative checkbox' }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={c}
                                        // secondary={c.external_url}
                                    />


                                </ListItem>

                            </div>
                        ))}
                    </List>
                </Paper>
            </Container>
        </Grid>
    </>;
};