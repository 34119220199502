import { createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import StartHereLeft from '../widgets/StartHereLeft';
import { CompetitionLinksWidget } from '../widgets/CompetitionLinksWidget';
import { BookmarksWidget } from '../widgets/BookmarksWidget';
import { NewsLetterWidget } from './NewsLetterWidget';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 0,

        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inherit',
            },
        },
    }),
);


export default function LeftColumn({}): ReactElement {
    const classes = useStyles();

    return (
        <aside className={classes.root}>
            <div className={classes.sectionDesktop}>
                <NewsLetterWidget />
                <br />
                {/*<Divider variant={'middle'} />*/}
                <BookmarksWidget />
                <br />
                <Divider variant={'middle'} />
            </div>
            <div className={classes.sectionDesktop}>
                <CompetitionLinksWidget source={'upcoming'} elements={5} />
                <br />
                <CompetitionLinksWidget source={'recent'} elements={7} />
            </div>
        </aside>
    );
}
