import { Button, createStyles, Divider, Fab, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { classicNameResolver } from 'typescript';
import NavigationIcon from '@material-ui/icons/Navigation';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { CommentCount } from 'disqus-react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { LiveTv } from '@material-ui/icons';


declare global {
    interface Window {
        Twitch: any;
    }
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            flexGrow: 1,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            backgroundColor: '#202225',
            boxShadow: '4px 4px 4px rgba(10, 10, 10, .3)',
            marginBottom: theme.spacing(2),
            borderRadius: 5,
        },
        verticallyCentered: {
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
            // marginRight: 5,
        },
        centerBox: {
            backgroundColor: '#464242',
            padding: 10,
            paddingTop: 20,
            paddingBottom: 20,
            paddingRight: 20,
            borderRadius: 5,
            marginTop: theme.spacing(1),

        },
        tripleHeader: {
            display: 'flex',
            padding: theme.spacing(),
            paddingBottom: 0,
            marginBottom: theme.spacing(),

        },
        twitchplayer: {
            // minHeight: '30vw'
        marginTop: 10,
        },
    }),
);

interface Props {
    channelName: string;
}

export const TwitchPost = (props: Props) => {
    const classes = useStyles();

    useEffect(() => {


        const script = document.createElement('script');

        let embed;

        script.src = 'https://embed.twitch.tv/embed/v1.js';
        script.async = true;

        script.addEventListener('load', () => {
            embed = new window.Twitch.Player('twitch-embed', {
                channel: props.channelName,
                muted: 'true',
                width: '100%',
                height: '400px',
                parent: ['localhost'],
            });
        });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    const round_url = `/ProgrammingCompetitions/consid/considition/2022/considition-2022-circular-e-commerce-deliveries`;


    const roundCaption = `Considition 2022 - Circular E-commerce deliveries`;


    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item sm={12} xl={2} md={2} xs={12} lg={12} className={classes.verticallyCentered}>
                    <LiveTv color={'primary'} fontSize={'large'} style={{ marginRight: 10 }} />
                    <Typography variant={'button'} className={classes.verticallyCentered}>Live</Typography>
                </Grid>
                <Grid item sm={12} xl={6} md={6} xs={12} lg={12} className={classes.verticallyCentered}>
                    <Typography variant={'h6'}>
                        <Link component={RouterLink}
                              to={round_url}
                              color={'inherit'}>{roundCaption}</Link>

                    </Typography>
                </Grid>

                <Grid item sm={8} xl={3} md={3} xs={8} lg={8} className={classes.verticallyCentered}>

                    <Typography>&nbsp;</Typography>

                </Grid>

                <Grid item sm={4}  xl={1} md={1} xs={4} lg={4} className={classes.verticallyCentered}>

                    <Button
                        style={{marginLeft: 'auto'}}
                        variant='outlined'
                        size={'small'}
                        color='inherit'
                        href={"https://www.considition.com"}
                        target='_blank'
                        endIcon={<OpenInNewIcon />}
                    >
                        Open
                    </Button>

                </Grid>

            </Grid>

                    <div id='twitch-embed' className={classes.twitchplayer}></div>

            <div className={classes.tripleHeader}>

                <div style={{ paddingLeft: 5, paddingTop: 0, flexGrow: 1 }}>
                    &nbsp;
                </div>
                <div style={{ paddingTop: 0 }}>

                    <Link component={RouterLink} to={round_url} color={'inherit'}><Typography>Go to round
                        ---&gt;</Typography></Link>
                </div>

            </div>

        </div>
    );
};

TwitchPost.defaultProps = {
    targetID: 'twitch-embed',
    width: '940',
    height: '480',
};

