import Card from '@material-ui/core/Card';
import FlareIcon from '@material-ui/icons/Flare';
import Typography from '@material-ui/core/Typography';
import { Chip, createStyles, Divider, Link, makeStyles, Theme } from '@material-ui/core';
import CodeIcon from '@material-ui/icons/Code';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { FC } from 'react';
import {
    ContentCategory,
    ContentItem, ContentItemAttachment,
    FeedItemAttachmentSwitch,
    YoutubeAttachment,
} from '../../datatypes/feed-interfaces';
import LinkIcon from '@material-ui/icons/Link';
import { AdminMenu } from '../Feed/AdminMenu';
import { LikeMenu } from '../Feed/LikeMenu';
import { ContentTypeIcon } from '../Feed/ContentTypeIcon';
import GitHubIcon from '@material-ui/icons/GitHub';
import { SelectAll } from '@material-ui/icons';

interface Props {
    content: ContentItem,
    is_selected: boolean

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        selectedCard: {
          backgroundColor: '#282A35',
            border: '1px dashed #89FFEA'
        },
        nonselectedCard: {

        },
        verticallyCentered: {
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        tripleHeader: {
            display: 'flex',
            padding: theme.spacing(),
            paddingBottom: 0,
            marginBottom: theme.spacing(),

        },
        headerMiddle: {
            flexGrow: 1,
            marginLeft: 10,
            marginTop: 'auto',
            marginBottom: 'auto',

        },
        media: {
            height: 120,
            width: '100%',
            // paddingTop: '56.25%', // 16:9
        },
        horizontalitem: {
            marginRight: theme.spacing(1),
        },
        githubLink: {
            marginRight: 5,
            "&:hover": {
                // backgroundColor: "#505050",
                transform: "scale(1.1)",
                cursor: "pointer",

            },
        }
    }));


function GetGithub(item : ContentItem){

            const pattern = /https:\/\/github.com\/([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gim;
            const url = pattern.exec(item.description);
            return url && url.length > 0 ? url[0] : null;

}


export const ProblemContentCard: FC<Props> = (props: Props) => {

    const classes = useStyles();

    const github_url = GetGithub(props.content);

    return <Card className={props.is_selected ? classes.selectedCard : classes.nonselectedCard} id={'item' + (props.content.attachment.type=='youtube' ? '-' + props.content.attachment.video_id : '-' + props.content.id) }>
        <div className={classes.tripleHeader}>
            <div className={classes.verticallyCentered}>
                <ContentTypeIcon content_category={props.content.content_category}/>
                {/*{props.content.official_content ?*/}
                {/*    <FlareIcon color={'secondary'} titleAccess={'Official'} />*/}
                {/*    :*/}
                {/*    null*/}
                {/*}*/}
            </div>
            <div className={classes.headerMiddle}>
                {/*<Typography variant={'overline'} component={'h2'}*/}
                {/*            color={'inherit'}>*/}
                {/*    {props.content.content_category == ContentCategory.Unknown ? <>&nbsp;</> : ContentCategory[props.content.content_category]}*/}
                {/*</Typography>*/}

            </div>
            <div className={classes.verticallyCentered}>


                {github_url ?
                    <Link target={'_blank'} href={github_url} color={'inherit'} className={classes.githubLink}><GitHubIcon />

                    </Link>
                    :
                     null
                }

                {props.content.programming_language ?
                    // <Typography variant={'overline'} style={{color:"whitesmoke"}} >{'<' + props.content.programming_language + '>'}</Typography>
                    <Chip size='small'
                          variant='outlined'
                          color='default'
                        // icon={<CodeIcon />}
                          label={'<' + props.content.programming_language + '>'}
                          style={{ color: '#FB9583' }}
                    />
                    : null

                }
            </div>

        </div>

        <CardMedia
            className={classes.media}
        >
            <Divider />
            <FeedItemAttachmentSwitch attachment={props.content.attachment} height={120} />
            <Divider />

        </CardMedia>
        <CardContent>
            <div className={classes.tripleHeader}>
                <div
                    className={`${classes.verticallyCentered} ${classes.horizontalitem}`}>
                    {props.content.attachment.type === 'youtube' ?
                        <YouTubeIcon color={'disabled'} /> :
                        <LinkIcon color={'secondary'} />
                    }

                </div>
                <div className={classes.verticallyCentered}>
                    <Link target={"_blank"} href={props.content.author.external_url}>{props.content.author.name}</Link>
                </div>
            </div>
            <Typography variant={'caption'} component={'p'}>{props.content.title}</Typography>
            {props.content.attachment.type == 'youtube' ?
                    <div>
                        <LikeMenu video_id={props.content.attachment.video_id}/>
                        <AdminMenu title={props.content.title} video_id={props.content.attachment.video_id} />
                    </div>
                    :
                    <></>
            }
        </CardContent>
    </Card>;
};