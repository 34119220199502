import React, { FC} from 'react';
import { Container, Grid } from '@material-ui/core';
import { CompetitionBreadcrumbsSkeleton } from './components/Competitions/CompetitionBreadcrumbsSkeleton';
import { CompetitionHeaderSkeleton } from './components/Competitions/CompetitionHeaderSkeleton';

export const CalendarSkeleton: FC = () => {
    return <Grid item sm={12} md={7}>
        <Container maxWidth={false}>

            <CompetitionHeaderSkeleton  />

        </Container>


    </Grid>;
};