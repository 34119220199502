import { Box, Button, Chip, createStyles, Grid, Link, makeStyles, Theme } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { FC } from 'react';
import {
    CompetitionOrganizationLink,
    CompetitionProblemData,
    CompetitionRoundProblemLink,
} from '../../datatypes/competition-round-view-data';
import { ProblemContentLoader } from './ProblemContentLoader';
import CardContent from '@material-ui/core/CardContent';
import { StatisticsViewer } from './StatisticsViewer';
import { Link as RouterLink } from 'react-router-dom';
import { CommentCount } from 'disqus-react';
import AlarmIcon from '@material-ui/icons/Alarm';

interface Props {

    organization_slug: string,
    competition_slug: string,
    year: number,
    round_slug: string,
    problem: CompetitionRoundProblemLink,
    show_as_part_of_problem_list: boolean,
    programming_language_filter: string[]

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {},
            cards: {
                padding: 20,
                // marginTop: 20,
            },
            problemContainer: {
                // border: '1px solid rgba(100, 181, 246,0.3)',

                padding: 10,
                borderRadius: 5,
                boxShadow: '2px 2px 5px #1c1c1c',
                backgroundColor: 'rgba(100, 100, 100,0.05)',

                // backgroundColor: 'rgba(100, 181, 246,0.05)',
            },
            verticallyCentered: {
                display: 'flex',
                marginTop: 'auto',
                marginBottom: 'auto',
            },
            commentLink: {
                marginLeft: 'auto',
                marginRight: 'auto',

                [theme.breakpoints.up('xs')]: {
                    marginLeft: 0,
                },
                [theme.breakpoints.up('sm')]: {
                    marginLeft: 0,
                },
                [theme.breakpoints.up('md')]: {
                    marginLeft: 0,
                },
                [theme.breakpoints.up('xl')]: {
                    marginLeft: 'auto',
                }, [theme.breakpoints.up('lg')]: {
                    marginLeft: 'auto',
                },

            },

            titleItem: {
                order: 1,
            },
            statisticsItem: {
                order: 2,
                [theme.breakpoints.up('lg')]: {
                    order: 3,
                }, [theme.breakpoints.up('xl')]: {
                    order: 2,
                },
            },
            commentItem: {
                order: 3,
                [theme.breakpoints.up('lg')]: {
                    order: 2,
                },
                [theme.breakpoints.up('xl')]: {
                    order: 3,
                },

            },
            openButtonItem: {

                order: 4,
                [theme.breakpoints.up('lg')]: {
                    order: 2,
                },
                [theme.breakpoints.up('xl')]: {
                    order: 4,
                },
            },
            problemContentItem: {
                order: 5,
            },

        },
    ));

export const ProblemContentContainer: FC<Props> = (props: Props) => {

    const classes = useStyles();
    const baseUrl = `/ProgrammingCompetitions/${props.organization_slug}/${props.competition_slug}/${props.year}/${props.round_slug}/`;



    return <>
        <Box className={classes.cards} id={`problem-${props.problem.slug}`}>
            <Grid container spacing={2} className={classes.problemContainer}>

                <Grid item sm={12} xl={7} md={12} xs={12} lg={8}
                      className={`${classes.verticallyCentered} ${classes.titleItem}`}>
                    <Typography variant={'h5'} color={'inherit'}>
                        {props.show_as_part_of_problem_list ?
                            <Link color={'inherit'} component={RouterLink}
                                  to={baseUrl + props.problem.slug}>{props.problem.title}</Link>
                            :
                            <span>{props.problem.title}</span>
                        }
                    </Typography>
                </Grid>

                <Grid item sm={12} xl={2} md={12} xs={12} lg={12}
                      className={`${classes.verticallyCentered} ${classes.statisticsItem}`}>

                    {props.problem.test_sets.map(ts =>
                        <div key={ts.id} style={{ width: '100%' }}>
                            <StatisticsViewer s={ts}  />
                        </div>,
                    )}

                </Grid>

                <Grid item sm={9} xl={2} md={9} xs={9} lg={2}
                      className={`${classes.verticallyCentered} ${classes.commentItem}`}>
                    {props.show_as_part_of_problem_list ?
                        <Link component={RouterLink}
                              to={baseUrl + props.problem.slug + '#comments'} className={classes.commentLink}>
                            <CommentCount
                                shortname='competitivecoders'
                                config={{
                                    url: `https://www.competitivecoders.com/ProgrammingCompetitions/${props.organization_slug}/${props.competition_slug}/${props.year}/${props.round_slug}/${props.problem.slug}`,
                                    identifier: `${props.organization_slug}-${props.competition_slug}-${props.year}-${props.round_slug}-${props.problem.slug}`,
                                    title: `CompetitiveCoders.com - ${props.organization_slug} ${props.competition_slug} ${props.year} - ${props.round_slug} - ${props.problem.title}`,
                                }}>
                                Comments
                            </CommentCount>
                        </Link>
                        :
                        <></>}
                </Grid>

                <Grid item sm={3} xl={1} md={3} xs={3} lg={2}
                      className={`${classes.verticallyCentered} ${classes.openButtonItem}`}
                      style={{ marginLeft: 'auto' }}>

                    <Button
                        variant='outlined'
                        size={'small'}
                        color='secondary'
                        href={props.problem.external_url}
                        target='_blank'
                        endIcon={<OpenInNewIcon />}
                        style={{ marginLeft: 'auto' }}
                    >
                        Open
                    </Button>

                </Grid>


                <Grid item sm={12} className={classes.problemContentItem}>
                    <ProblemContentLoader organization_slug={props.organization_slug}
                                          competition_slug={props.competition_slug}
                                          year={props.year.toString()}
                                          round_slug={props.round_slug}
                                          problem_slug={props.problem.slug}
                                          limit={null}
                                          programming_language_filter={props.programming_language_filter}
                    />
                </Grid>
            </Grid>


            {/*    <Box className={classes.problemContainer}>*/}
            {/*        <CardHeader*/}
            {/*            avatar={null}*/}
            {/*            action={*/}
            {/*                <Button*/}
            {/*                    variant='outlined'*/}
            {/*                    size={'small'}*/}
            {/*                    color='secondary'*/}
            {/*                    href={props.problem.external_url}*/}
            {/*                    target='_blank'*/}
            {/*                    endIcon={<OpenInNewIcon />}*/}
            {/*                >*/}
            {/*                    Open*/}
            {/*                </Button>*/}
            {/*            }*/}
            {/*            title={<div style={{ flexGrow: 1, display: 'flex' }}>*/}


            {/*                {props.problem.test_sets.map(ts =>*/}
            {/*                    <div key={ts.id} style={{ marginLeft: 20, marginTop: 'auto', marginBottom: 'auto' }}>*/}
            {/*                        <StatisticsViewer s={ts} width={200} />*/}
            {/*                    </div>*/}
            {/*                )}*/}

            {/*                <div style={{ marginLeft: 20, marginTop: 'auto', marginBottom: 'auto' }}>*/}
            {/*                    {props.show_as_part_of_problem_list ?*/}
            {/*                        <Link component={RouterLink}*/}
            {/*                          to={baseUrl + props.problem.slug + "#comments"}>*/}
            {/*                    <CommentCount*/}
            {/*                        shortname='competitivecoders'*/}
            {/*                        config={{*/}
            {/*                            url: `https://www.competitivecoders.com/ProgrammingCompetitions/${props.organization_slug}/${props.competition_slug}/${props.year}/${props.round_slug}/${props.problem.slug}`,*/}
            {/*                            identifier: `${props.organization_slug}-${props.competition_slug}-${props.year}-${props.round_slug}-${props.problem.slug}`,*/}
            {/*                            title: `CompetitiveCoders.com - ${props.organization_slug} ${props.competition_slug} ${props.year} - ${props.round_slug} - ${props.problem.title}`,*/}
            {/*                        }}>*/}
            {/*                        Comments*/}
            {/*                    </CommentCount>*/}
            {/*                    </Link>*/}
            {/*                        :*/}
            {/*                        <></>}*/}
            {/*                </div>*/}

            {/*            </div>}*/}
            {/*        />*/}
            {/*        <CardContent>*/}
            {/*            /!*<Grid container xs={12} spacing={2}>*!/*/}
            {/*                <ProblemContentLoader organization_slug={props.organization_slug}*/}
            {/*                                      competition_slug={props.competition_slug}*/}
            {/*                                      year={props.year.toString()}*/}
            {/*                                      round_slug={props.round_slug}*/}
            {/*                                      problem_slug={props.problem.slug}*/}
            {/*                                      limit={null}*/}
            {/*                />*/}
            {/*            /!*</Grid>*!/*/}
            {/*        </CardContent>*/}
            {/*    </Box>*/}

        </Box>

    </>;
};