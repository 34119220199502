import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import React, { FC } from 'react';
import { ContentCategory } from '../../datatypes/feed-interfaces';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import AssistantIcon from '@material-ui/icons/Assistant';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
interface Props {
    content_category: ContentCategory;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        category_icon: {
            boxShadow: '5px 5px 10px #333',
        },
    }));
export const ContentTypeIcon: FC<Props> = ({ content_category }) => {

    const classes =useStyles();

    const choose_icon = () => {
        switch (content_category) {
            case ContentCategory.Unknown:
                return <BlurOnIcon fontSize={'large'} color={'disabled'}  titleAccess={"Uncategorized content"}/>;
            case ContentCategory.Introduction:
                return <EmojiEmotionsIcon fontSize={'large'} htmlColor={'#FB7FBD'} titleAccess={'Introduction'}/>
            case ContentCategory.Solution:
                return <AssistantIcon fontSize={'large'} className={classes.category_icon} htmlColor={'#90FF8A'} titleAccess={'Solution'}/>
            case ContentCategory.Screencast:
                return <PersonalVideoIcon fontSize={'large'} className={classes.category_icon} htmlColor={'#89FFEA'} titleAccess={'Screencast'}/>
            case ContentCategory.ProblemSolving:
                return <PlaylistAddCheckIcon fontSize={'large'} className={classes.category_icon} titleAccess={'Problem solving'}/>
            case ContentCategory.Algorithmic:
                return <CastForEducationIcon fontSize={'large'} className={classes.category_icon} htmlColor={'#FEFF8B'} titleAccess={'Algorithmic'}/>
            case ContentCategory.Other:
                return <ArtTrackIcon fontSize={'large'} className={classes.category_icon} htmlColor={'#967FFA'} titleAccess={'Other content'}/>

            default:
                return <></>;
        }
    };
    return <>{choose_icon() }</>;
};