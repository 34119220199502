import React, { FC } from 'react';
import {
    CompetitionLink,
    CompetitionOrganizationData,
    CompetitionOrganizationLink,
} from '../../datatypes/competition-round-view-data';
import { Helmet } from 'react-helmet';
import { createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { ContestBreadcrumbs } from './ContestBreadcrumbs';
import { CompetitionHeader } from './CompetitionHeader';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import { ProblemContentLoader } from './ProblemContentLoader';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';


export interface CompetitionOrganizationViewData {
    organization: CompetitionOrganizationLink,
    competitions: CompetitionLink[]
}

interface Props {
    organization_data: CompetitionOrganizationViewData
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        middlecolumn: {
            boxShadow: '-2px 0px 5px #222, 2px 0px 5px #222',
            backgroundColor: '#1D1E26',
            marginTop: theme.spacing(2),
            borderRadius: 2

        },
        problemListItem: {
            // border: '1px solid black',
            // maxHeight: 100,
            padding: 20,
            margin: 10,
            borderRadius: 10,
            // maxHeight: 120,
            boxShadow: '2px 2px 5px #1c1c1c',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                backgroundColor: '#414557',
                transform: 'scale(1.01)',
                cursor: 'pointer',

            },
        },
    }),
);

export const CompetitionOrganizationView: FC<Props> = (props ) => {

    const classes = useStyles();

    const baseUrl = `/ProgrammingCompetitions/${props.organization_data.organization.slug}/`;

    const organization_link:CompetitionOrganizationLink = {
        title: props.organization_data.organization.title,
        slug: props.organization_data.organization.slug,
        external_url: props.organization_data.organization.external_url,
        image: 'tba',
        id: 'tba'
    }

    return <>
        <Helmet>
            <title>{`Solutions to ${props.organization_data.organization.title}'s programming competitions!`}</title>
            <meta
                name="description"
                content={`See other's solutions and walkthroughs of ${props.organization_data.organization.title}'s programming competitions on CompetitiveCoders.com!`}
            />
        </Helmet>
        <Grid item sm={12} xl={7} lg={6} className={classes.middlecolumn} component={'main'}>

            <ContestBreadcrumbs organization={organization_link}
                                competition={null} competition_round={null}
                                year={null} problem={null}/>

            <CompetitionHeader competition_round={null} competition={null} organization={organization_link} year={null}/>

            <List component="nav"  aria-label="Rounds" dense={true}>
                {props.organization_data.competitions.map((c) => (
                    <div key={c.slug}>
                        <ListItem button dense={true} component={RouterLink} to={ baseUrl + c.slug} className={classes.problemListItem} >
                            <ListItemIcon>
                                <EmojiEventsIcon />
                            </ListItemIcon>
                            <ListItemText primary={c.title}  />

                        </ListItem>

                    </div>
                ))}

            </List>

            <br/>
            <hr/>
            <br/>

            <ProblemContentLoader organization_slug={props.organization_data.organization.slug}
                                  competition_slug={""}
                                  year={""}
                                  round_slug={""}
                                  problem_slug={""}
                                  limit={20}
                                  programming_language_filter={[]}
            />


        </Grid>
    </>;
}