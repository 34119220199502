import { Container, Grid, Link } from '@material-ui/core';
import { CalendarLoader } from './CalendarLoader';
import RightColumn from './components/RightColumn';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

interface RouteParams {
    year: string,

}

export const CalendarWrapper = () => {


    const params = useParams<RouteParams>();


    const year = parseInt(params.year) > 2000 ? parseInt(params.year) : new Date().getFullYear();

    return <>
        <div>
            <div style={{float: 'right'}}>
                {year < new Date().getFullYear() ? <Link component={RouterLink} to={`/calendar/${year+1}`}>{`${year +1} -->`}</Link> : null}
            </div>
            <div style={{textAlign: 'left'}}>
                {year > 2007 ? <Link component={RouterLink} to={`/calendar/${Math.min(year-1, new Date().getFullYear())}`}>{`<-- ${Math.min(year-1, new Date().getFullYear())}`}</Link> : null}
            </div>

        </div>
        <CalendarLoader year={year} /></>;

};