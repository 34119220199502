import { Avatar, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { CompetitionTestSetData } from '../../datatypes/competition-round-view-data';

export interface Props {
    s: CompetitionTestSetData,

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 10,
            backgroundColor: 'lightcoral',
            boxShadow: '4px 4px 4px rgba(10, 10, 10, .3)',
            marginBottom: theme.spacing(2),
            borderRadius: 10,
        },
        progress: {

            height: 10,
            border: '1px solid #202020',
            borderRadius: 5,
            // background: 'linear-gradient(to right, red 15%, lightgreen 15%, lightgreen 27%, green 27%,green 70%, lightgray 70%)'
        },
    }),
);

export const StatisticsViewer: FC<Props> = (props) => {
    const classes = useStyles();

    if (props.s.participants_total == 0) {
        return <></>;
    }


    const failed = props.s.participants_attempted == props.s.fully_accepted ?
       0
        :
        props.s.participants_attempted - props.s.fully_accepted - props.s.partially_accepted;

    const red = Math.floor(failed / props.s.participants_total * 100);

    const lightgreen = Math.floor(props.s.partially_accepted / props.s.participants_total * 100);

    const green =props.s.participants_attempted == props.s.fully_accepted ?
        Math.floor(props.s.fully_accepted / props.s.participants_total * 100)
        :
        Math.floor(props.s.fully_accepted / props.s.participants_attempted * 100);

    const gray = Math.floor(props.s.participants_attempted / props.s.participants_total * 100);


    const gradient = `linear-gradient(to right, #FB7FBD ${red}%, #D2FFCF ${red}%, #D2FFCF ${red + lightgreen}%, #90FF8A ${lightgreen + red}%, #90FF8A ${green + lightgreen + red}%, #1D1E26 ${gray}%)`;

    const hover = `Failed: ${failed == 0 ? 'n/a' : failed}\nSome points: ${props.s.partially_accepted == 0 ? '-' : props.s.partially_accepted}\nFull points: ${props.s.fully_accepted}\nParticipants: ${props.s.participants_total}`;

    return <div className={classes.progress} style={{ background: gradient }} title={hover}></div>;
};