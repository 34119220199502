import * as React from 'react';
import { YoutubeAttachmentPanel } from '../components/Feed/FeedItems/YoutubeAttachmentPanel';
import { LinkAttachmentPanel } from './link-attachment-panel';
import { CompetitionRoundLink } from './competition-round-view-data';

export enum ContentCategory {

    Unknown = 0,
    Introduction = 1,
    Solution = 2,
    Screencast = 3,
    ProblemSolving = 4,
    Algorithmic = 5,
    Other = 6,

}

export interface Author {
    id: string,
    name: string,
    external_url: string,
    thumbnail: string
}

export interface ContentItem {
    id: string,
    author: Author,
    content_category: ContentCategory,
    description: string,
    official_content: boolean,
    timestamp: Date,
    title: string,
    subtitle: string,
    programming_language: string;
    attachment: ContentItemAttachment,
    round: CompetitionRoundLink | null
}

export type ContentItemAttachment = YoutubeAttachment | LinkAttachment | BlogAttachment | GitHubCodeAttachment;


export interface YoutubeAttachment {
    type: 'youtube',
    video_id: string,
    views: number,
    likes: number,
    comments: number
}

export interface LinkAttachment {
    type: 'link';
    external_url: string;
    image_url: string;
}

export interface GitHubCodeAttachment {
    type: 'githubcode',
    external_url: string,
    repository: string,
}

export interface BlogAttachment {
    title: string;
    image_url: string;
    type: 'blog';
    external_url: string;
}

interface FeedItemPanelProps {
    feed_item: ContentItem,

}

export const FeedItemAttachmentSwitch: React.FC<{ attachment: ContentItemAttachment, height?: number }> = ({
                                                                                                        attachment,
                                                                                                        height = 350,
                                                                                                    }) => {
    switch (attachment.type) {
        case 'youtube':
            return (<YoutubeAttachmentPanel attachment={attachment}  />);
        case 'blog':
            return (<BlogAttachmentPanel attachment={attachment} />);
        case 'link':
            return <LinkAttachmentPanel attachment={attachment} height={height} />;
        case 'githubcode':
            return <GithubCodeAttachmentPanel attachment={attachment}  />;
    }
};

const BlogAttachmentPanel: React.FC<{ attachment: BlogAttachment }> = ({
                                                                           attachment,
                                                                       }) => {
    return (<div>

        <img src={attachment.image_url} alt={attachment.title} />
        <a href={attachment.external_url}>{attachment.external_url}</a>
    </div>);
};


const GithubCodeAttachmentPanel: React.FC<{ attachment: GitHubCodeAttachment }> = ({ attachment }) => {
    return (<div>
        <b>{attachment.repository}</b>
        <a href={attachment.external_url}>{attachment.external_url}</a>
    </div>);
};
