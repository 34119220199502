import { Box, Button, createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { FC } from 'react';
import {
    CompetitionLink,
    CompetitionOrganizationLink,
    CompetitionRoundLink,
} from '../../datatypes/competition-round-view-data';

interface Props {
    competition_round: CompetitionRoundLink | null,
    competition: CompetitionLink | null,
    organization: CompetitionOrganizationLink,
    year: number | null

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // marginBottom: theme.spacing(4),
        },
        splitBoxRow: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        splitBoxText: {
            flexGrow: 1,
        },

    }),
);


export const CompetitionHeader: FC<Props> = (props) => {
    const classes = useStyles();

    const external_url = props.competition_round != null ? props.competition_round.external_url :
        props.competition != null ? props.competition.external_url : props.organization.external_url;



    return <div className={classes.root}>
        <Box className={classes.splitBoxRow}>


            <Typography variant='h3' component='h1' className={classes.splitBoxText}>
                {props.organization.slug != "advent-of-code" ? props.organization.title + " " : ""}
                {props.competition != null ? props.competition.title : ''}
                &nbsp;
                {props.year != null && props.organization.slug != 'leetcode' ? props.year : ''}


            </Typography>
            <Button
                variant='outlined'
                size={'small'}
                color='secondary'
                href={external_url}
                target='_blank'
                endIcon={<OpenInNewIcon />}

            >
                Open in new window
            </Button>
        </Box>

        {props.competition_round != null ?
            <Typography variant='h4' component='h2'>
                {`${props.organization.slug == 'leetcode' ? '#' : ''}${props.competition_round.title}`}
            </Typography> : <></>
        }

        {props.competition_round != null ?
            <Typography variant='subtitle1'>{props.competition_round.start_time.toLocaleString()}</Typography>
            : <></>
        }

        
        {props.organization.slug == 'advent-of-code' ?
            <Typography variant={'overline'}><Link target={'_blank'} href={"https://adventofcode.com/2022/support"} style={{color: '#22C62E'}}>Don&apos;t forget to support Advent of Code!</Link></Typography> : null }
    </div>
        ;
};