import SettingsIcon from '@material-ui/icons/Settings';
import React, { FC } from 'react';
import {
    Avatar,
    createStyles,
    Dialog, DialogContent,
    DialogTitle,
    Divider, Grid, Link,
    List,
    ListItem, ListItemAvatar, ListItemIcon,
    ListItemText, ListSubheader,
    makeStyles, Paper,
    Theme,
} from '@material-ui/core';
import {
    getClassification,
    getCompetitions,
    getOrganizations,
    getProblems,
    getRounds,
    removeProblem,
    setProblem,
    setProgrammingLanguage,
    updateCompetition,
    updateContentCategory,
    updateOrganization,
    updateRound,
    updateYoutubeVideoIgnored,
    updateYoutubeVideoRelevancy,
} from '../../data/adminapi';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ContentCategory } from '../../datatypes/feed-interfaces';
import LanguageIcon from '@material-ui/icons/Language';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import YouTubeIcon from '@material-ui/icons/YouTube';

import {
    CompetitionData,
    CompetitionLink, CompetitionOrganizationLink, CompetitionRoundLink, CompetitionRoundProblemLink,
    CompetitionRoundViewData,
} from '../../datatypes/competition-round-view-data';
import { LinearProgress } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Checkbox } from '@material-ui/core';
import { FormatListNumbered } from '@material-ui/icons';
import { ProgrammingLanguageFilterBox } from '../Competitions/ProgrammingLanguageFilterBox';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adminButton: {
            float: 'right',
            '&:hover': {
                // backgroundColor: '#505050',
                transform: 'scale(1.05)',
                cursor: 'pointer',

            },
        },
        hidden: {
            display: 'none',
        },
    }),
);

interface Props {
    title: string,
    video_id: string
}




export const AdminMenu: FC<Props> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [show_loader, setShowLoader] = React.useState(false);

    const [round_url, setRoundUrl] = React.useState('');

    const [response_message, setResponse_message] = React.useState('response');
    const [response_color, setResponse_color] = React.useState('white');

    const [selected_organization, setSelectedOrganization] = React.useState('');
    const [selected_competition, setSelectedCompetition] = React.useState('');
    const [selected_round, setSelectedRound] = React.useState('');
    const [selected_year, setSelectedYear] = React.useState('');

    const [competitions, setCompetitions] = React.useState([] as CompetitionLink[]);
    const [rounds, setRounds] = React.useState([] as CompetitionRoundLink[]);
    const [organizations, setOrganizations] = React.useState([] as CompetitionOrganizationLink[]);

    const [problems, setProblems] = React.useState([] as CompetitionRoundProblemLink[]);

    const [programming_language, set_programming_language] = React.useState("");


    const [selected_category, setSelectedCategory] = React.useState(-1);




    const handleClickOpen = async () => {
        setOpen(true);
        await loadOrganizations();
        await loadClassification();
    };

    const handleClose = () => {
        setOpen(false);
    };


    const loadOrganizations = async () => {

        const orgs = await getOrganizations();
        setOrganizations(orgs);

    };

    const loadClassification = async () => {
        setShowLoader(true);
        const classification = await getClassification(props.video_id);


        if (classification.programming_language && classification.programming_language != ''){
            set_programming_language(classification.programming_language);
        }

        setSelectedCategory(classification.content_category);

        setSelectedOrganization(classification.organization_id);
        const competitions = await getCompetitions(classification.organization_id);
        setCompetitions(competitions);

        setSelectedCompetition(classification.competition_id);

        setSelectedYear(classification.year);
        const rounds = await getRounds(classification.competition_id, parseInt(classification.year));
        setRounds(rounds);

        if (classification.round_id && classification.round_id != null && classification.round_id != '00000000-0000-0000-0000-000000000000') {

            setSelectedRound(classification.round_id);
            const filteredRound = rounds.filter((r: CompetitionRoundLink) => r.id==classification.round_id);
            if (filteredRound.length > 0) {
                const url = filteredRound[0].external_url;
                setRoundUrl(url);

                const problems = await getProblems(classification.round_id, props.video_id);
                setProblems(problems);
            }
        }

        setShowLoader(false);
    };

    const handleContentCategoryClick = async (category: number) => {
        setShowLoader(true);
        const result = await updateContentCategory(props.video_id, category);

        if (!result) {
            setResponse_color('lightcoral');
            setResponse_message(`Set category: ${category} FAILED!`);

        } else {
            setResponse_color('lightgreen');
            setResponse_message(`Set category ${category} successful!`);
            setSelectedCategory(category);
        }
        setShowLoader(false);
    };

    const handleCompetitionSelect = async (command: string, value: string) => {
        setShowLoader(true);
        switch (command) {
            case 'set_organization':

                await updateOrganization(props.video_id, value);
                setSelectedOrganization(value.toLowerCase());

                const competitions = await getCompetitions(value);
                setCompetitions(competitions);
                break;
            case 'set_competition':
                await updateCompetition(props.video_id, value);
                setSelectedCompetition(value.toLowerCase());
                break;

            case 'set_year':
                setSelectedYear(value);

                const rounds = await getRounds(selected_competition, parseInt(value));
                setRounds(rounds);
                break;

            case 'set_round':
                await updateRound(props.video_id, value);
                setSelectedRound(value);

                const problems = await getProblems(value, props.video_id);
                setProblems(problems);
                break;

            case 'set_problem':

                await setProblem(props.video_id, value);
                const newproblems = await getProblems(selected_round, props.video_id);
                setProblems(newproblems);
                break;

            case 'remove_problem':
                await removeProblem(props.video_id, value);
                const newerproblems = await getProblems(selected_round, props.video_id);
                setProblems(newerproblems);
                break;

             case 'set_programming_language':
                 if (value == '<Unknown>'){
                     value = '';
                 }
                await setProgrammingLanguage(props.video_id, value);

                 set_programming_language(value);
                // const newerproblems = await getProblems(selected_round, props.video_id);
                // setProblems(newerproblems);
                break;


        }

        setShowLoader(false);


    };


    const handleRelevancyClick = async (value: string) => {
        setShowLoader(true);
        let result = false;

        switch (value) {
            case 'SetIrrelevant':
                result = await updateYoutubeVideoRelevancy(props.video_id, false);
                break;
            case 'SetRelevant':
                result = await updateYoutubeVideoRelevancy(props.video_id, true);
                break;
            case 'SetIgnored':
                result = await updateYoutubeVideoIgnored(props.video_id, true);
                break;
            case 'SetNotIgnored':
                result = await updateYoutubeVideoIgnored(props.video_id, false);
                break;

            default:
                alert('Unknown command: ' + value);
        }

        if (!result) {
            setResponse_color('lightcoral');
            setResponse_message(`${value} FAILED!`);
        } else {
            setResponse_color('lightgreen');
            setResponse_message(`${value} successful!`);
        }

        setShowLoader(false);
    };



    return <div className={process.env.NODE_ENV === 'development' ? classes.adminButton : classes.hidden}>
        <SettingsIcon onClick={handleClickOpen} color={response_color == 'lightgreen' ? 'primary' : 'inherit'} />


        <Dialog onClose={handleClose} maxWidth={'lg'} fullWidth={true} open={open}>
            <LinearProgress hidden={!show_loader} />
            <DialogTitle><span>{props.title} <Link href={`https://www.youtube.com/watch?v=${props.video_id}`} target={'_blank'}><YouTubeIcon /></Link></span></DialogTitle>
            <Divider />
            <div style={{ padding: 10, color: response_color, marginLeft: 'auto', marginRight: 'auto' }}>
                <Typography variant={'h6'}>{response_message}</Typography>
            </div>
            <Divider />
            <DialogContent>


                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <List>
                            <ListItem button onClick={() => handleRelevancyClick('SetIrrelevant')}>
                                <ListItemIcon>
                                    <DeleteForeverIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText primary={'Irrelevant (REMOVE)'} />
                            </ListItem>

                            <ListItem button onClick={() => handleRelevancyClick('SetRelevant')}>
                                <ListItemText primary={'* Relevant'} inset={true} />
                            </ListItem>

                            <Divider variant='inset' component='li' />

                            <ListItem button onClick={() => handleRelevancyClick('SetIgnored')}>
                                <ListItemIcon>
                                    <VisibilityOffIcon color={'primary'} />
                                </ListItemIcon>
                                <ListItemText primary={'Ignore (hide from ML)'} />
                            </ListItem>

                            <ListItem button onClick={() => handleRelevancyClick('SetNotIgnored')}>
                                <ListItemText primary={'* Not Ignored'} inset={true} />
                            </ListItem>
                            <Divider variant='inset' component='li' />
                        </List>
                        <List
                            subheader={<ListSubheader component='div'
                                                      id='nested-list-subheader'>Category</ListSubheader>}>

                            {Object.values(ContentCategory).slice(0, Object.keys(ContentCategory).length / 2).map((k, v) =>
                                <ListItem button
                                          key={props.video_id + k}
                                          selected={selected_category == v}
                                          onClick={() => handleContentCategoryClick(v)}>
                                    <ListItemIcon>
                                        {/*<DeleteForeverIcon color={'primary'} />*/}
                                    </ListItemIcon>
                                    <ListItemText primary={`${k}: ${v}`} />
                                </ListItem>,
                            )}

                        </List>
                    </Grid>
                    <Grid item xs={3} style={{ backgroundColor: '#1D1E26' }}>
                        <List dense={true}

                              subheader={<ListSubheader component='div' disableSticky={true} inset={true}>
                                  <><LanguageIcon /><span style={{marginLeft: 10,marginTop: 'auto', marginBottom: 'auto'}}>Organization</span></>
                                        </ListSubheader>}>

                            {organizations.map(v =>
                                <ListItem button
                                          key={props.video_id + v.id}
                                          selected={selected_organization === v.id}
                                          onClick={() => handleCompetitionSelect('set_organization', v.id)}>
                                    <ListItemText primary={`${v.title}`} />
                                </ListItem>,
                            )}

                        </List>
                        <Divider/>
                        <List dense={true}
                              subheader={<ListSubheader component='div' disableSticky={true} inset={true}>

                                  <><ArtTrackIcon /><span style={{marginLeft: 10,marginTop: 'auto', marginBottom: 'auto'}}>Competition</span></>
                        </ListSubheader>}>

                            {competitions.map(v =>
                                <ListItem button
                                          key={props.video_id + v.id}
                                          selected={selected_competition == v.id}
                                          onClick={() => handleCompetitionSelect('set_competition', v.id)}>
                                    <ListItemText primary={`${v.title}`} />
                                </ListItem>,
                            )}

                        </List>
                        <Divider/>
                        <List dense={true}
                              subheader={<ListSubheader component='div' disableSticky={true} inset={true}>
                                  <><EventIcon /><span style={{marginLeft: 10,marginTop: 'auto', marginBottom: 'auto'}}>Year</span></>
                        </ListSubheader>}>

                            {['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011'].map(v =>
                                <ListItem button
                                          key={props.video_id + v}
                                          selected={selected_year == v}
                                          onClick={() => handleCompetitionSelect('set_year', v)}>
                                    <ListItemText primary={v} />
                                </ListItem>,
                            )}

                        </List>

                    </Grid>
                    <Grid item xs={3} >

                        <List dense={true}
                              subheader={<ListSubheader component='div' disableSticky={true} inset={true}>
                                  <><EmojiEventsIcon /><span style={{marginLeft: 10,marginTop: 'auto', marginBottom: 'auto'}}>Round</span><Link style={{display: round_url != '' ? 'inline' : ' none'}} target={"_blank"} href={round_url} >GOTO</Link></>
                        </ListSubheader>}>

                            {rounds.map(v =>
                                <ListItem button
                                          key={props.video_id + v.id}
                                          selected={selected_round == v.id}
                                          onClick={() => handleCompetitionSelect('set_round', v.id).then(r => setRoundUrl(v.external_url))}>
                                    <ListItemText primary={`${v.title}`} />
                                </ListItem>,
                            )}

                        </List>
                    </Grid>
                    <Grid item xs={3} style={{ backgroundColor: '#1D1E26' }}>
                        <List dense={true}
                              subheader={<ListSubheader component='div' disableSticky={true} inset={true}>
                                  <><FormatListNumbered /><span style={{marginLeft: 10,marginTop: 'auto', marginBottom: 'auto'}}>Problems</span></>
                              </ListSubheader>}>

                            {problems.map(p =>

                                <ListItem button
                                          key={props.video_id + p.id}

                                          onClick={() => handleCompetitionSelect((p.selected ? 'remove_problem' : 'set_problem'), p.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={p.selected}
                                            tabIndex={-1}
                                            disableRipple
                                            //inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`${p.title}`} />
                                </ListItem>,
                            )}

                        </List>


                        <ProgrammingLanguageFilterBox selected_languages={[programming_language]} callbackFn={(language) => handleCompetitionSelect('set_programming_language', language).then(r => set_programming_language(language))} sticky={false} show_title={false}/>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>;
};